/*********************
 GENERAL UI COLORS 
*********************/

/***
Colors
blue:  #4b8df8
light blue: #bfd5fa
red: #e02222
yellow: #ffb848
green: #35aa47
purple: #852b99
dark: #555555;
light grey: #fafafa;
***/

/*********************
 GENERAL RESET & SETUP 
*********************/

/***
Reset and overrides  
***/

/* general body settings */
body {
  color: #000;
  font-family: 'Open Sans', sans-serif;
  padding: 0px !important;
  margin: 0px !important;
  font-size: 13px;
  direction: ltr;
}

/*
  Internet Explorer 10 doesn't differentiate device width from viewport width, and thus doesn't 
  properly apply the media queries in Bootstrap's CSS. To address this, 
  you can optionally include the following CSS and JavaScript to work around this problem until Microsoft issues a fix.
  */
@-webkit-viewport {
  width: device-width;
}

@-moz-viewport {
  width: device-width;
}

@-ms-viewport {
  width: device-width;
}

@-o-viewport {
  width: device-width;
}

@viewport {
  width: device-width;
}

/* Internet Explorer 10 doesn't differentiate device width from viewport width, 
  and thus doesn't properly apply the media queries in Bootstrap's CSS. To address this, following CSS code applied */
@-ms-viewport {
  width: auto !important;
}

/***
  Custom Scrollbars
  ***/

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background-color: #eaeaea;
  border-left: 1px solid #cecece;
}

::-webkit-scrollbar-thumb {
  background-color: #cecece;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}

::-webkit-scrollbar-track {
  border-radius: 0;
  box-shadow: none;
  border: 0;
}

::-webkit-scrollbar-thumb {
  border-radius: 0;
  box-shadow: none;
  border: 0;
}

/***
  General typography 
  ***/
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  color: #444;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300 !important;
}

h1.block,
h2.block,
h3.block,
h4.block,
h5.block,
h6.block {
  padding-top: 10px;
  padding-bottom: 10px;
}

a {
  text-shadow: none !important;
  color: #0d638f;
}

.cap : {
  text-transform: capitalize;
}

/***
  Fix link outlines after click
  ***/
a,
a:focus,
a:hover,
a:active {
  outline: 0;
}

/***
  General backgrounds. Can be applied to any block or panel
  ***/

.bg-blue {
  background-image: none !important;
  background-color: #4b8df8 !important;
  border-color: #4b8df8 !important;
  color: #fff !important;
}

.bg-red {
  background-image: none !important;
  background-color: #e02222 !important;
  border-color: #e02222 !important;
  color: #fff !important;
}

.bg-yellow {
  background-image: none !important;
  background-color: #ffb848 !important;
  border-color: #ffb848 !important;
  color: #fff !important;
}

.bg-green {
  background-image: none !important;
  background-color: #35aa47 !important;
  border-color: #35aa47 !important;
  color: #fff !important;
}

.bg-purple {
  background-image: none !important;
  background-color: #852b99 !important;
  border-color: #852b99 !important;
  color: #fff !important;
}

.bg-dark {
  background-image: none !important;
  background-color: #555555 !important;
  border-color: #555555 !important;
  color: #fff !important;
}

.bg-grey {
  background-image: none !important;
  background-color: #fafafa !important;
  border-color: #fafafa !important;
}

/***
  Font Awesome Icons
  ***/

[class^="fa-"],
[class*=" fa-"] {
  display: inline-block;
  margin-top: 1px;
  font-size: 14px;
  *margin-right: .3em;
  line-height: 14px;
}

/***
  Make font awesome icons fixed width(latest version issue)
  ***/

li [class^="fa-"],
li [class*=" fa-"] {
  display: inline-block;
  width: 1.25em;
  text-align: center;
}

li [class^="fa-"].icon-large,
li [class*=" fa-"].icon-large {
  /* increased font size for icon-large */
  width: 1.5625em;
}

.fa-lg,
.icon-lg {
  font-size: 16px;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.icon-default {
  color: #ccc;
}

.icon-success {
  color: #468847;
}

.icon-info {
  color: #27a9e3;
}

.icon-warning {
  color: #dbc056;
}

.icon-danger {
  color: #B94A48;
}

/***
  Close icon used for modal dialog and other UI element close buttons
  ***/
.close {
  display: inline-block;
  margin-top: 0px;
  margin-right: 0px;
  width: 9px;
  height: 9px;
  background-repeat: no-repeat !important;
  text-indent: -10000px;
  outline: none;
  background-image: url("assets/img/remove-icon-small.png") !important;
}

/***
  General HR
  ***/

hr {
  margin: 20px 0;
  border: 0;
  border-top: 1px solid #E0DFDF;
  border-bottom: 1px solid #FEFEFE;
}

/***
  Tools
  ***/

.display-none,
.display-hide {
  display: none;
}

.no-space {
  margin: 0px !important;
  padding: 0px !important;
}

.no-margin {
  margin: 0;
}

.no-border {
  border: 0 !important;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}


.margin-top-10 {
  margin-top: 10px !important;
}

.margin-bottom-15 {
  margin-bottom: 15px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-bottom-25 {
  margin-bottom: 25px !important;
}

.margin-right-10 {
  margin-right: 10px !important;
}

.bold {
  font-weight: 600 !important;
}

.fix-margin {
  margin-left: 0px !important
}

.border {
  border: 1px solid red;
}

.inline {
  display: inline;
}

.text-align-reverse {
  text-align: right;
}

/***
  ie8 & ie9 modes
  ***/

.visible-ie8 {
  display: none;
}

.ie8 .visible-ie8 {
  display: inherit !important;
}

.visible-ie9 {
  display: none;
}

.ie9 .visible-ie9 {
  display: inherit !important;
}

.hidden-ie8 {
  display: inherit;
}

.ie8 .hidden-ie8 {
  display: none !important;
}

.hidden-ie9 {
  display: inherit;
}

.ie9 .hidden-ie9 {
  display: none !important;
}

/********************
   GENERAL LAYOUT 
  *********************/

/***
  Header and header elements.
  ***/

.header.navbar {
  width: 100%;
  padding: 0 20px 0 20px;
  margin: 0;
  border: 0px;
  padding: 0px;
  box-shadow: none;
  height: 42px;
  min-height: 42px;
}

.header.navbar.navbar-fixed-top {
  z-index: 9995 !important;
}

.header.navbar .navbar-brand {
  display: inline-block;
  margin-top: -1px;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
  width: 225px;
  height: 42px;
  color: #ffffff;
}

.header.navbar .navbar-brand img {
  margin-left: 20px;
}

.header.navbar .navbar-brand.text-logo {
  padding-left: 20px;
  padding-top: 12px;
}

.header.navbar .navbar-toggle {
  margin: 8px 6px 4px 6px;
  padding: 0;
  padding-top: 2px;
  padding-bottom: 6px;
  background-image: none;
  filter: none;
  box-shadow: none;
  color: #fff;
  border: 0;
}

.header.navbar .navbar-toggle:hover {
  text-decoration: none;
  background: none;
}

.header.navbar .navbar-nav {
  margin-right: 20px;
  display: block;
}

.header.navbar .navbar-nav>li {
  margin: 0px;
  padding: 0px;
}

.header.navbar .navbar-nav>li.dropdown,
.header.navbar .navbar-nav>li.dropdown>a {
  padding-left: 4px;
  padding-right: 4px;
}

.header.navbar .navbar-nav>li.dropdown>a:last-child {
  padding-right: 0;
}

.header.navbar .navbar-nav>li.dropdown:last-child {
  padding-right: 2px;
}

.header.navbar .navbar-nav>li.dropdown .dropdown-toggle {
  margin: 0px;
  padding: 15px 10px 7px 10px;
}

.header.navbar .navbar-nav>li.dropdown .dropdown-toggle>i {
  font-size: 18px;
}

.header.navbar .navbar-nav>li.dropdown .dropdown-menu>li>a>i {
  font-size: 14px;
}

.header.navbar .navbar-nav>li.dropdown.user .dropdown-toggle {
  padding: 7px 0px 6px 6px;
}

.header.navbar .navbar-nav>li.dropdown.user .dropdown-toggle:hover {
  text-decoration: none;
}

.header.navbar .navbar-nav>li.dropdown.user .dropdown-toggle .username {
  color: #ddd;
}

.header.navbar .navbar-nav>li.dropdown.user .dropdown-toggle i {
  display: inline-block;
  margin-top: 5px;
  margin: 0;
  font-size: 16px;
}

.header.navbar .navbar-nav>li.dropdown.user .dropdown-menu i {
  width: 15px;
  display: inline-block;
}

.header.navbar .navbar-nav>li.dropdown .dropdown-toggle .badge {
  position: absolute;
  top: 8px;
  right: 20px;
}

/***
  Header Search
  ***/
.header.navbar .search-form {
  float: left;
  display: inline-block;
  padding: 0;
  height: 41px;
  margin: 0;
}

.header.navbar .search-form .form-control {
  margin-top: 8px;
  border: 0;
  padding-top: 1px;
  padding-right: 27px;
}

.header.navbar .search-form .submit {
  position: relative;
  display: block;
  float: right;
  margin-top: -21px;
  margin-right: 8px;
  width: 13px;
  height: 15px;
  box-shadow: none;
  border: 0px;
  padding: 0px;
  background-color: none;
  background-repeat: no-repeat !important;
  outline: none !important;
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.header.navbar .search-form .submit:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}

/***
  Language Bar
  ***/

.header.navbar .navbar-nav>li.dropdown.language {
  padding-left: 0;
  padding-right: 0;
  margin: 0;
}

.header.navbar .navbar-nav>li.dropdown.language>a {
  color: #ddd;
  font-size: 13px;
  padding: 11px 1px 11px 5px;
}

.header.navbar .navbar-nav>li.dropdown.language>a>img {
  margin-bottom: 2px;
}

.header.navbar .navbar-nav>li.dropdown.language>a>i {
  font-size: 16px;
}

.header.navbar .navbar-nav>li.dropdown.language>.dropdown-menu>li>a>img {
  margin-bottom: 2px;
}

.header.navbar .navbar-nav .dropdown-menu {
  margin-top: 3px;
}

/***
  Page container
  ***/

.page-container {
  margin: 0px;
  padding: 0px;
  position: relative;
}

.page-container:before,
.page-container:after {
  display: table;
  content: " ";
}

.page-container:after {
  clear: both;
}

.page-header-fixed .page-container {
  margin-top: 42px;
}

/*** IE 8 Fixes ***/
/***
  Page sidebar
  ***/

.ie8 .page-sidebar {
  width: 225px;
  float: left;
  position: relative;
  margin-right: -100%;
}

/***
  Page content
  ***/

.ie8 .page-content-wrapper {
  float: left;
  width: 100%;
}

.ie8 .page-content {
  margin-left: 225px;
  margin-top: 0px;
  min-height: 760px;
  padding: 25px 20px 20px 20px;
}

/*** IE 8 Fixes ***/

/***
  Page sidebar
  ***/

.page-sidebar.navbar-collapse {
  padding: 0;
}

.page-sidebar-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  margin: 0;
  padding: 0;
}

.page-sidebar-menu>li {
  display: block;
  margin: 0;
  padding: 0;
  border: 0px;
}

.page-sidebar-menu>li.start>a {
  border-top-color: transparent !important;
}

.page-sidebar-menu>li:last-child>a,
.page-sidebar-menu>li.last>a {
  border-bottom-color: transparent !important;
}

.page-sidebar-menu>li>a {
  display: block;
  position: relative;
  margin: 0;
  border: 0px;
  padding: 10px 15px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 300;
}

.page-sidebar-fixed .page-sidebar-menu>li>a {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.page-sidebar-reversed.page-sidebar-fixed .page-sidebar-menu>li>a {
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
}

.page-sidebar-menu>li>a i {
  font-size: 16px;
  margin-right: 5px;
  text-shadow: none;
}

.page-sidebar-menu>li.break {
  margin-bottom: 20px;
}

.page-sidebar-menu>li.open>a {
  font-size: 14px;
}

.page-sidebar-menu>li.active>a {
  border: none;
  text-shadow: none;
  font-size: 14px;
}

.page-sidebar-menu>li.active>a .selected {
  display: block;
  width: 8px;
  height: 25px;
  background-image: url("assets/img/sidebar-menu-arrow.png");
  float: right;
  position: absolute;
  right: 0px;
  top: 8px;
}

.page-sidebar-reversed .page-sidebar-menu>li.active>a .selected {
  background-image: url("assets/img/sidebar-menu-arrow-reverse.png");
  right: auto;
  left: 0;
}

.page-sidebar ul>li>a>.arrow:before {
  float: right;
  margin-top: 0px;
  margin-right: 5px;
  display: inline;
  font-size: 16px;
  font-family: FontAwesome;
  height: auto;
  content: "\f104";
  font-weight: 300;
  text-shadow: none;
}

.page-sidebar-menu>li>a>.arrow.open:before {
  float: right;
  margin-top: 0px;
  margin-right: 3px;
  display: inline;
  font-family: FontAwesome;
  height: auto;
  font-size: 16px;
  content: "\f107";
  font-weight: 300;
  text-shadow: none;
}

/* bagin: sidebar menu badges */
.page-sidebar-menu li>a>.badge {
  float: right;
  margin-top: 1px;
  margin-right: 13px;
}

/* end: sidebar menu badges */

.page-sidebar-menu .sub-menu {
  padding: 0;
}

.page-sidebar-menu>li>ul.sub-menu {
  display: none;
  list-style: none;
  clear: both;
  margin: 8px 0px 8px 0px;
}

.page-sidebar-menu>li.active>ul.sub-menu {
  display: block;
}

.page-sidebar-menu>li>ul.sub-menu>li {
  background: none;
  margin: 0px;
  padding: 0px;
  margin-top: 1px !important;
}

.page-sidebar-menu>li>ul.sub-menu>li>a {
  display: block;
  margin: 0px 0px 0px 0px;
  padding: 5px 0px;
  padding-left: 44px !important;
  text-decoration: none;
  font-size: 14px;
  font-weight: 300;
  background: none;
}

/* 3rd level sub menu */
.page-sidebar-menu>li>ul.sub-menu>li ul.sub-menu {
  display: none;
  list-style: none;
  clear: both;
  margin: 0px 0px 0px 0px;
}

.page-sidebar-menu>li>ul.sub-menu li>a>.arrow:before {
  float: right;
  margin-top: 1px;
  margin-right: 20px;
  display: inline;
  font-size: 16px;
  font-family: FontAwesome;
  height: auto;
  content: "\f104";
  font-weight: 300;
  text-shadow: none;
}

.page-sidebar-menu>li>ul.sub-menu li>a>.arrow.open:before {
  float: right;
  margin-top: 1px;
  margin-right: 18px;
  display: inline;
  font-family: FontAwesome;
  height: auto;
  font-size: 16px;
  content: "\f107";
  font-weight: 300;
  text-shadow: none;
}

.page-sidebar-menu>li.active>ul.sub-menu>li.active ul.sub-menu {
  display: block;
}

.page-sidebar-menu>li>ul.sub-menu>li ul.sub-menu li {
  background: none;
  margin: 0px;
  padding: 0px;
  margin-top: 1px !important;
}

.page-sidebar-menu>li>ul.sub-menu li>ul.sub-menu>li>a {
  display: block;
  margin: 0px 0px 0px 0px;
  padding: 5px 0px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 300;
  background: none;
}

.page-sidebar-menu>li>ul.sub-menu>li>ul.sub-menu>li>a {
  padding-left: 60px;
}

.page-sidebar-menu>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li>a {
  padding-left: 80px;
}

.page-sidebar-menu>li.active>ul.sub-menu>li.active ul.sub-menu>li.active ul.sub-menu {
  display: block;
}


.page-sidebar-menu>li>ul.sub-menu li>ul.sub-menu>li>a>i {
  font-size: 13px;
}

/***
  Sidebar Search
  ***/

.page-sidebar .sidebar-search {
  padding: 0;
  margin: 0;
}

.page-sidebar .header.navbar-responsive-search {
  display: none;
}

.page-sidebar .sidebar-search .form-container {
  margin: 15px 20px 15px 20px;
  height: 35px;
  padding-top: 7px;
}

.page-sidebar .sidebar-search .form-container .submit {
  display: block;
  float: right;
  margin-top: 3px;
  width: 13px;
  height: 15px;
  background-repeat: no-repeat;
  box-shadow: none;
  border: 0px;
  padding: 0px;
  outline: none !important;
}

.page-sidebar .sidebar-search .form-container input[type="text"] {
  margin: 0px;
  width: 165px;
  border: 0px;
  padding: 0 !important;
  font-size: 14px !important;
  box-shadow: none !important;
  font-size: 14px;
  font-weight: normal;
}

.page-sidebar .sidebar-search .form-container input[type="text"]:focus {
  outline: none !important;
}

/***
  Sidebar toggler(show/hide)
  ***/
.sidebar-toggler {
  cursor: pointer;
  opacity: 0.5;
  filter: alpha(opacity=50);
  width: 29px;
  height: 29px;
  background-repeat: no-repeat;
}

.sidebar-toggler:hover {
  filter: alpha(opacity=100);
  opacity: 1;
}

.page-sidebar .sidebar-toggler {
  margin-top: 15px;
  margin-left: 175px;
}

.header.navbar .sidebar-toggler {
  float: left;
  display: inline-block;
  margin-top: 6px;
  margin-left: -42px;
}

/***
  Page content
  ***/
.page-content {
  margin-top: 0px;
  padding: 0px;
  background-color: #fff;
}

.ie8 .page-content {
  padding: 20px;
  margin-left: 225px;
  margin-top: 0px;
  min-height: 760px;
}

.ie8 .page-sidebar-fixed .page-content {
  min-height: 600px;
}

.ie8 .page-content.no-min-height {
  min-height: auto;
}

.page-full-width .page-content {
  margin-left: 0px !important;
}

.page-full-width .page-sidebar-menu {
  display: none;
}

/***
  Page title
  ***/
.page-title {
  padding: 0px;
  font-size: 30px;
  letter-spacing: -1px;
  display: block;
  color: #666;
  margin: 0px 0px 15px 0px;
  font-weight: 300;
  font-family: 'Open Sans', sans-serif;
}

.page-title small {
  font-size: 14px;
  letter-spacing: 0px;
  font-weight: 300;
  color: #888;
}

/***
  Page breadcrumb
  ***/

.ie8 .row .page-breadcrumb.breadcrumb>li {
  margin-right: 1px;
}

.page-content .page-breadcrumb.breadcrumb {
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  box-shadow: none;
  padding-right: 30px;
  padding-left: 8px;
  margin-top: 15px;
  margin-bottom: 25px;
  border: 0px !important;
  background-color: #eee;
}

.page-content .page-breadcrumb.breadcrumb>li>a,
.page-content .page-breadcrumb.breadcrumb>li>i,
.page-content .page-breadcrumb.breadcrumb>li>span {
  color: #333;
  font-size: 14px;
  text-shadow: none;
}

.page-content .page-breadcrumb.breadcrumb>li>i {
  color: #666;
}

.page-content .page-breadcrumb.breadcrumb>li+li:before {
  display: none;
}

/* Dashboard breadcrumb Dropdown */
.page-content .page-breadcrumb.breadcrumb .btn-group {
  right: 15px;
  position: absolute;
  margin-top: -8px;
}

.page-content .page-breadcrumb.breadcrumb>.btn-group .btn {
  padding-top: 8px;
  padding-bottom: 8px;
  color: #ffffff;
}

/* Dashboard date range panel */
.page-content .page-breadcrumb.breadcrumb .dashboard-date-range {
  position: relative;
  top: -8px;
  margin-right: -30px;
  display: none;
  padding: 9px 9px 8px 9px;
  cursor: pointer;
  color: #fff;
  background-color: #e02222;
}

/* hack for chrome and safari */
@media all and (-webkit-min-device-pixel-ratio:0) {
  .page-content .page-breadcrumb.breadcrumb .dashboard-date-range {
    padding: 9px;
  }
}

.page-content .page-breadcrumb.breadcrumb .dashboard-date-range>span {
  font-size: 12px;
  font-weight: 300;
  color: #fff;
  text-transform: uppercase;
}

.page-content .page-breadcrumb.breadcrumb .dashboard-date-range>.fa-calendar {
  text-transform: none;
  color: #fff;
  margin-top: 0px;
  font-size: 14px;
}

.page-content .page-breadcrumb.breadcrumb .dashboard-date-range>.fa-angle-down {
  color: #fff;
  font-size: 16px;
}

/***
  Footer
  ***/

.footer {
  padding: 8px 20px 5px 20px;
  font-size: 12px;
}

.footer:after,
.footer:before {
  content: "";
  display: table;
  line-height: 0;
}

.footer:after {
  clear: both;
}

.footer .footer-inner {
  float: left;
  display: inline-block;
}

.footer .footer-tools {
  float: right;
  display: inline-block;
}

.footer .footer-tools .go-top {
  display: block;
  text-decoration: none;
  cursor: pointer;
  margin-top: -2px;
  margin-right: 0px;
  margin-bottom: 0px;
  font-size: 16px;
  padding: 0px 6px 0px 6px;
}

.footer .footer-tools .go-top i {
  font-size: 22px;
  margin-bottom: 5px;
}


/********************
   GENERAL UI ELEMENTS 
  *********************/

/***
  Icon stuff
  ***/
i.icon,
a.icon {
  color: #999;
  margin-right: 5px;
  font-weight: normal;
  font-size: 13px;
}

i.icon-black {
  color: #000 !important;
}

a.icon:hover {
  text-decoration: none;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  opacity: .4;
  filter: alpha(opacity=40);
}

a.icon.huge i {
  font-size: 16px !important;
}

i.big {
  font-size: 20px;
}

i.warning {
  color: #d12610;
}

i.critical {
  color: #37b7f3;
}

i.normal {
  color: #52e136;
}

/***
  Custom wells
  ***/
.well {
  background-color: #fafafa;
  border: 1px solid #eee;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.well.mini {
  padding: 7px !important;
}

/***
  Form stuff
  ***/


/***
  Bordered form layout
  ***/

/***
  Input icons
  ***/

/* input with right aligned and colored icons */

/* input with left aligned icons */
.input-icon {
  position: relative;
}


.input-icon input {
  padding-left: 33px !important;
}

.input-icon i {
  color: #ccc;
  display: block;
  position: absolute;
  margin: 11px 2px 4px 10px;
  width: 16px;
  height: 16px;
  font-size: 16px;
  text-align: center;
}

.input-icon.right input {
  padding-left: 12px !important;
  padding-right: 33px !important;
}

.input-icon.right i {
  right: 8px;
  float: right;
}

.has-success .input-icon>i {
  color: #468847;
}

.has-warning .input-icon>i {
  color: #c09853;
}

.has-error .input-icon>i {
  color: #b94a48;
}

/***
  Portlets
  ***/
.portlet {
  clear: both;
  margin-top: 0px;
  margin-bottom: 25px;
  padding: 0px;
}

.portlet>.portlet-title {
  margin-bottom: 15px;
  border-bottom: 1px solid #eee;
}

.portlet>.portlet-title:after,
.portlet>.portlet-title:before {
  content: "";
  display: table;
  line-height: 0;
}

.portlet>.portlet-title:after {
  clear: both;
}

.portlet>.portlet-title>.caption {
  float: left;
  display: inline-block;
  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  margin-bottom: 8px;
}

.portlet>.portlet-title>.caption>i {
  float: left;
  margin-top: 4px;
  display: inline-block !important;
  font-size: 13px;
  margin-right: 5px;
  color: #666;
}

.portlet.blue>.portlet-title>.caption,
.portlet.green>.portlet-title>.caption,
.portlet.yellow>.portlet-title>.caption,
.portlet.red>.portlet-title>.caption,
.portlet.purple>.portlet-title>.caption,
.portlet.grey>.portlet-title>.caption {
  color: #fff;
}

.portlet.box.blue>.portlet-title>.caption>i,
.portlet.box.green>.portlet-title>.caption>i,
.portlet.box.grey>.portlet-title>.caption>i,
.portlet.box.yellow>.portlet-title>.caption>i,
.portlet.box.red>.portlet-title>.caption>i,
.portlet.box.purple>.portlet-title>.caption>i,
.portlet.box.light-grey>.portlet-title>.caption>i {
  color: #fff;
}

.sortable .portlet>.portlet-title {
  cursor: move;
}

.portlet>.portlet-title>.tools,
.portlet>.portlet-title>.actions {
  display: inline-block;
  padding: 0;
  margin: 0;
  margin-top: 6px;
  float: right;
}

.portlet>.portlet-title>.tools>a {
  display: inline-block;
  height: 16px;
  margin-left: 5px;
}

.portlet>.portlet-title>.actions>.dropdown-menu i {
  color: #000 !important;
}

.portlet>.portlet-title>.tools>a.remove {
  margin-bottom: 2px;
  background-image: url(assets/img/portlet-remove-icon.png);
  background-repeat: no-repeat;
  width: 11px;
}

.portlet>.portlet-title>.tools>a.config {
  margin-bottom: 2px;
  background-image: url(assets/img/portlet-config-icon.png);
  background-repeat: no-repeat;
  width: 12px;
}

.portlet>.portlet-title>.tools>a.reload {
  margin-bottom: 2px;
  background-image: url(assets/img/portlet-reload-icon.png);
  width: 13px;
}

.portlet>.portlet-title>.tools>a.expand {
  margin-bottom: 2px;
  background-image: url(assets/img/portlet-expand-icon.png);
  width: 14px;
}

.portlet>.portlet-title>.tools>a.collapse {
  margin-bottom: 2px;
  background-image: url(assets/img/portlet-collapse-icon.png);
  width: 14px;
}

.portlet>.portlet-title>.tools>a:hover {
  text-decoration: none;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  opacity: .6;
  filter: 'alpha(opacity=60)';
}

.portlet>.portlet-title>.actions>.btn-group {
  margin-top: -13px;
}

.portlet>.portlet-title>.actions>.btn {
  padding: 4px 10px;
  margin-top: -14px;
}

.portlet>.portlet-title>.actions>.btn-group>.btn {
  padding: 4px 10px;
  margin-top: -1px;
}

.portlet>.portlet-title>.actions>.btn.btn-sm {
  padding: 3px 8px;
  margin-top: -13px;
}

.portlet>.portlet-title>.actions>.btn-group>.btn-sm {
  padding: 3px 8px;
  margin-top: -1px;
}

.portlet>.portlet-title>.pagination.pagination-sm {
  float: right !important;
  display: inline-block !important;
  margin: 0px;
  margin-top: -4px;
}

@media (max-width: 767px) {

  .portlet>.portlet-title>.actions.btn-set>.btn-group,
  .portlet>.portlet-title>.actions.btn-set>.btn {
    margin-top: 0px;
    margin-bottom: 5px;
  }
}

.portlet>.portlet-body {
  clear: both;
  padding: 0;
}

.portlet>.portlet-empty {
  min-height: 125px;
}

.portlet>.portlet-body.light-blue,
.portlet.light-blue {
  background-color: #bfd5fa !important;
}

.portlet>.portlet-body.blue,
.portlet.blue {
  background-color: #4b8df8 !important;
}

.portlet>.portlet-body.red,
.portlet.red {
  background-color: #e02222 !important;
}

.portlet>.portlet-body.yellow,
.portlet.yellow {
  background-color: #ffb848 !important;
}

.portlet>.portlet-body.green,
.portlet.green {
  background-color: #35aa47 !important;
}

.portlet>.portlet-body.purple,
.portlet.purple {
  background-color: #852b99 !important;
}

.portlet>.portlet-body.light-grey,
.portlet.light-grey {
  background-color: #fafafa !important;
}

.portlet>.portlet-body.grey,
.portlet.grey {
  background-color: #555555 !important;
}

/*  draggable girds */

.ui-sortable-placeholder {
  border: 1px dotted black;
  visibility: visible !important;
  height: 100% !important;
}

.ui-sortable-placeholder * {
  visibility: hidden;
}

.sortable-box-placeholder {
  background-color: #f5f5f5;
  border: 1px dashed #DDDDDD;
  display: block;
  /* float: left;*/
  margin-top: 0px !important;
  margin-bottom: 24px !important;
}

.sortable-box-placeholder * {
  visibility: hidden;
}

/***
  Solid colored portlet
  ***/
.portlet.solid {
  padding: 10px;
}

.portlet.solid>.portlet-title>.tools {
  margin-top: 2px;
  border: 0px;
}

.portlet.solid>.portlet-title {
  margin-bottom: 5px;
  border: 0px;
}

.portlet.solid.bordered>.portlet-title {
  margin-bottom: 15px;
}

.portlet.solid.red>.portlet-title,
.portlet.solid.red>.portlet-title>.caption>i,
.portlet.solid.red>.portlet-body,

.portlet.solid.green>.portlet-title,
.portlet.solid.green>.portlet-title>.caption>i,
.portlet.solid.green>.portlet-body,

.portlet.solid.yellow>.portlet-title,
.portlet.solid.yellow>.portlet-title>.caption>i,
.portlet.solid.yellow>.portlet-body,

.portlet.solid.grey>.portlet-title,
.portlet.solid.grey>.portlet-title>.caption>i,
.portlet.solid.grey>.portlet-body,

.portlet.solid.purple>.portlet-title,
.portlet.solid.purple>.portlet-title>.caption>i,
.portlet.solid.purple>.portlet-body,

.portlet.solid.blue>.portlet-title,
.portlet.solid.blue>.portlet-title>.caption>i,
.portlet.solid.blue>.portlet-body {
  border: 0;
  color: #fff;
}

.portlet.bordered {
  border-left: 2px solid #ddd;
}

/***
  Box portlet
  ***/

.portlet.box {
  padding: 0px !important
}

.portlet.box>.portlet-title {
  padding: 8px 10px 2px 10px;
  border-bottom: 1px solid #eee;
  color: #fff !important;
}

.portlet.box>.portlet-title>.tools {
  margin-top: 3px;
}

.portlet.box>.portlet-title>.tools>a.remove,
.portlet.solid>.portlet-title>.tools>a.remove {
  background-image: url(assets/img/portlet-remove-icon-white.png);
}

.portlet.box>.portlet-title>.tools>a.config,
.portlet.solid>.portlet-title>.tools>a.config {
  background-image: url(assets/img/portlet-config-icon-white.png);
}

.portlet.box>.portlet-title>.tools>a.reload,
.portlet.solid>.portlet-title>.tools>a.reload {
  background-image: url(assets/img/portlet-reload-icon-white.png);
}

.portlet.box>.portlet-title>.tools>a.expand,
.portlet.solid>.portlet-title>.tools>a.expand {
  background-image: url(assets/img/portlet-expand-icon-white.png);
}

.portlet.box>.portlet-title>.tools>a.collapse,
.portlet.solid>.portlet-title>.tools>a.collapse {
  background-image: url(assets/img/portlet-collapse-icon-white.png);
}

/* portlet buttons */
.portlet.box>.portlet-body {
  background-color: #fff;
  padding: 10px;
}

.portlet.box>.portlet-title {
  margin-bottom: 0px;
}

.portlet.box.blue>.portlet-title {
  background-color: #4b8df8;
}

.portlet.box.blue {
  border: 1px solid #b4cef8;
  border-top: 0;
}

.portlet.box.red>.portlet-title {
  background-color: #e02222;
}

.portlet.box.red {
  border: 1px solid #ef8476;
  border-top: 0;
}

.portlet.box.yellow>.portlet-title {
  background-color: #ffb848;
}

.portlet.box.yellow {
  border: 1px solid #fccb7e;
  border-top: 0;
}

.portlet.box.green>.portlet-title {
  background-color: #35aa47;
}

.portlet.box.green {
  border: 1px solid #77e588;
  border-top: 0;
}

.portlet.box.purple>.portlet-title {
  background-color: #852b99;
}

.portlet.box.purple {
  border: 1px solid #af5cc1;
  border-top: 0;
}

.portlet.box.grey>.portlet-title {
  background-color: #555555;
}

.portlet.box.grey {
  border: 1px solid #9d9c9c;
  border-top: 0;
}

.portlet.box.light-grey>.portlet-title {
  background-color: #aaa;
}

.portlet.box.light-grey {
  border: 1px solid #bbb;
  border-top: 0;
}

/***
  Charts and statistics
  ***/
.chart,
.pie,
.bars {
  overflow: hidden;
  height: 300px;
}

/***
  Statistic lists
  ***/
.item-list.table .percent {
  width: 30px;
  float: right;
  margin-right: 10px;
  margin-top: 3px;
}

/***
  Chart tooltips
  ***/
.chart-tooltip {
  clear: both;
  z-index: 100;
  background-color: #736e6e !important;
  padding: 5px !important;
  color: #fff;
}

.chart-tooltip .label {
  clear: both;
  display: block;
  margin-bottom: 2px;
}

/***
  Mini chart containers
  ***/
.bar-chart {
  display: none
}

.line-chart {
  display: none
}

/***
  Custom icon buttons
  ***/
.icon-btn {
  height: 60px;
  min-width: 80px;
  margin: 5px 5px 0 0;
  border: 1px solid #ddd;
  padding: 12px 0px 0px 0px;
  background-color: #fafafa !important;
  background-image: none !important;
  filter: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  display: inline-block !important;
  color: #646464 !important;
  text-shadow: none !important;
  text-align: center;
  cursor: pointer;
  position: relative;
  -webkit-transition: all 0.3s ease !important;
  -moz-transition: all 0.3s ease !important;
  -ms-transition: all 0.3s ease !important;
  -o-transition: all 0.3s ease !important;
  transition: all 0.3s ease !important;
}

.icon-btn i {
  font-size: 18px;
}

.ie8 .icon-btn:hover {
  filter: none !important;
}

.icon-btn:hover {
  text-decoration: none !important;
  border-color: #999 !important;
  color: #444 !important;
  text-shadow: 0 1px 0px rgba(255, 255, 255, 1) !important;
  -webkit-transition: all 0.3s ease !important;
  -moz-transition: all 0.3s ease !important;
  -ms-transition: all 0.3s ease !important;
  -o-transition: all 0.3s ease !important;
  transition: all 0.3s ease !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.icon-btn:hover .badge {
  -webkit-transition: all 0.3s ease !important;
  -moz-transition: all 0.3s ease !important;
  -ms-transition: all 0.3s ease !important;
  -o-transition: all 0.3s ease !important;
  transition: all 0.3s ease !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.icon-btn div {
  font-family: 'Open Sans', sans-serif;
  margin-top: 5px;
  margin-bottom: 20px;
  color: #000;
  font-size: 12px;
  font-weight: 300;
}

.icon-btn .badge {
  position: absolute;
  font-family: 'Open Sans', sans-serif;
  font-size: 11px !important;
  font-weight: 300;
  top: -5px;
  right: -5px;
  padding: 3px 6px 3px 6px;
  color: white !important;
  text-shadow: none;
  border-width: 0;
  border-style: solid;
  -webkit-border-radius: 12px !important;
  -moz-border-radius: 12px !important;
  border-radius: 12px !important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

/* extended dropdowns */
.dropdown-menu.extended {
  min-width: 160px !important;
  max-width: 300px !important;
  width: 233px !important;
  background-color: #ffffff !important;
}

.dropdown-menu.extended:before,
.dropdown-menu.extended:after {
  border-bottom-color: #ddd !important;
}

.dropdown-menu.extended li a {
  display: block;
  padding: 5px 10px !important;
  clear: both;
  font-weight: normal;
  line-height: 20px;
  white-space: normal !important;
}

.dropdown-menu.extended li i {
  margin-right: 3px;
}

.dropdown-menu.extended li a {
  font-size: 13px;
  padding: 10px !important;
  background-color: #ffffff;
}

.dropdown-menu.extended li a:hover {
  background-image: none;
  background-color: #f5f5f5;
  color: #000;
  filter: none;
}

.dropdown-menu.extended li p {
  padding: 10px;
  background-color: #eee;
  margin: 0px;
  font-size: 14px;
  font-weight: 300;
  color: #000;
}

.dropdown-menu.extended li a {
  padding: 7px 0 5px 0px;
  list-style: none;
  border-bottom: 1px solid #f4f4f4 !important;
  font-size: 12px;
  text-shadow: none;
}

.dropdown-menu.extended li:first-child a {
  border-top: none;
  border-bottom: 1px solid #f4f4f4 !important;
}

.dropdown-menu.extended li:last-child a {
  border-top: 1px solid white !important;
  border-bottom: 1px solid #f4f4f4 !important;
}

.dropdown-menu.extended li.external>a {
  font-size: 13px;
  font-weight: 400;
}

.dropdown-menu.extended li.external>a>i {
  margin-top: 3px;
  float: right;
}

/* header notifications dropdowns */
.dropdown-menu .dropdown-menu-list.scroller {
  padding-right: 0 !important;
  padding-left: 0;
  list-style: none;
}

.dropdown-menu.notification li>a .time {
  font-size: 12px;
  font-weight: 600;
  text-align: right;
  font-style: italic;
}

/* header inbox dropdowns */
.dropdown-menu.inbox li>a .photo {
  float: left;
  padding-right: 6px;
}

.dropdown-menu.inbox li>a .photo>img {
  height: 40px;
  width: 40px;
}

.dropdown-menu.inbox li>a .subject {
  display: block;
}

.dropdown-menu.inbox li>a .subject .from {
  font-size: 14px;
  font-weight: 400;
  color: #02689b;
}

.dropdown-menu.inbox li>a .subject .time {
  font-size: 12px;
  font-weight: 600;
  font-style: italic;
  position: relative;
  float: right;
}

.dropdown-menu.inbox li>a .message {
  display: block !important;
  font-size: 12px;
}

/* header tasks */
.dropdown-menu.tasks .task {
  margin-bottom: 5px;
}

.dropdown-menu.tasks .task .desc {
  font-size: 13px;
  font-weight: 300;
}

.dropdown-menu.tasks .task .percent {
  font-size: 14px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  float: right;
  display: inline-block;
}

.dropdown-menu.tasks .progress {
  display: block;
  height: 11px;
  margin: 0px;
}

/***
  General list for item with image
  ***/
.item-list li .img {
  height: 50px;
  width: 50px;
  float: left;
  margin-top: 3px;
  margin-right: 5px;
}

.item-list {
  margin: 0px;
  list-style: none;
}

.item-list li {
  padding: 7px 0 5px 0px;
  list-style: none;
  border-top: 1px solid white;
  border-bottom: 1px solid #EBEBEB;
  font-size: 12px;
}

.item-list li:first-child {
  border-top: none;
  border-bottom: 1px solid #EBEBEB;
}

.item-list li:last-child {
  border-top: none;
  border-bottom: none;
}

.item-list li .label {
  margin-right: 5px;
}

.item-list.todo li .label {
  position: absolute;
  right: 80px;
}

.item-list.todo li .actions {
  position: absolute;
  right: 45px;
}

/***
  Custom tables
  ***/
.table-toolbar {
  margin-bottom: 15px;
}

.table.table-full-width {
  width: 100% !important;
}

.table .m-btn {
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 5px;
}

.table thead tr th {
  font-size: 14px;
  font-weight: 600;
}

.table-advance {
  margin-bottom: 10px !important;
}

.table-advance thead {
  color: #999;
}

.table-advance thead tr th {
  background-color: #DDD;
  font-size: 14px;
  font-weight: 400;
  color: #666;
}

.table-advance div.success,
.table-advance div.info,
.table-advance div.important,
.table-advance div.warning,
.table-advance div.danger {
  position: absolute;
  margin-top: -5px;
  float: left;
  width: 2px;
  height: 30px;
  margin-right: 20px !important;
}

.table-advance tr td {
  border-left-width: 0px;
}

.table-advance tr td:first-child {
  border-left-width: 1px !important;
}

.table-advance tr td.highlight:first-child a {
  margin-left: 15px;
}

.table-advance td.highlight div.success {
  border-left: 2px solid #66ee66;
}

.table-advance td.highlight div.info {
  border-left: 2px solid #87ceeb;
}

.table-advance td.highlight div.important {
  border-left: 2px solid #f02c71;
}

.table-advance td.highlight div.warning {
  border-left: 2px solid #fdbb39;
}

.table-advance td.highlight div.danger {
  border-left: 2px solid #e23e29;
}

/***
  Star rating
  ***/
.rating {
  unicode-bidi: bidi-override;
  direction: rtl;
  font-size: 30px;
}

.rating span.star {
  font-family: FontAwesome;
  font-weight: normal;
  font-style: normal;
  display: inline-block;
}

.rating span.star:hover {
  cursor: pointer;
}

.rating span.star:before {
  content: "\f006";
  padding-right: 5px;
  color: #999999;
}

.rating span.star:hover:before,
.rating span.star:hover~span.star:before {
  content: "\f005";
  color: #e3cf7a;
}


/***
  Item block with details shown on hover
  ***/
.item {
  overflow: hidden;
  display: block;
  margin-bottom: 20px;
}

.item .details {
  width: 100%;
  display: none;
  background-color: #000;
  color: #fff !important;
  padding: 5px;
  text-align: center;
  position: relative;
  bottom: 30px;
  margin-bottom: -30px;
  overflow: hidden;
  z-index: 6;
}

.item:hover .details {
  display: block;
  opacity: 0.7;
  filter: alpha(opacity=70);
}

.item:hover .zoom-icon {
  opacity: 0.5;
  filter: alpha(opacity=50);
}

/***
  Zoom icon overlay on images
  ***/
.zoom {
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 5;
}

.zoom .zoom-icon {
  background-image: url("assets/img/overlay-icon.png");
  background-color: #222;
  background-repeat: no-repeat;
  background-position: 50%;
  position: absolute;
  width: inherit;
  height: inherit;
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: 6;
  top: 0;
}

/***
  Chats
  ***/
.chats {
  margin: 0;
  padding: 0;
  margin-top: -15px;
}

.chats li {
  list-style: none;
  padding: 5px 0;
  margin: 10px auto;
  font-size: 12px;
}

.chats li img.avatar {
  height: 45px;
  width: 45px;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  border-radius: 50% !important;
}

.chats li.in img.avatar {
  float: left;
  margin-right: 10px;
}

.chats li .name {
  color: #3590c1;
  font-size: 13px;
  font-weight: 400;
}

.chats li .datetime {
  color: #333;
  font-size: 13px;
  font-weight: 400;
}

.chats li.out img.avatar {
  float: right;
  margin-left: 10px;
}

.chats li .message {
  display: block;
  padding: 5px;
  position: relative;
}

.chats li.in .message {
  text-align: left;
  border-left: 2px solid #35aa47;
  margin-left: 65px;
  background: #fafafa
}

.chats li.in .message .arrow {
  display: block;
  position: absolute;
  top: 5px;
  left: -8px;
  width: 0;
  height: 0;

  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #35aa47;
}

.chats li.out .message .arrow {
  display: block;
  position: absolute;
  top: 5px;
  right: -8px;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #da4a38;
}

.chats li.out .message {
  border-right: 2px solid #da4a38;
  margin-right: 65px;
  background: #fafafa;
  text-align: right;
}

.chats li.out .name,
.chats li.out .datetime {
  text-align: right;
}

.chats li .message .body {
  display: block;
}

.chat-form {
  margin-top: 15px;
  padding: 10px;
  background-color: #e9eff3;
  overflow: hidden;
  clear: both;
}

.chat-form .input-cont {
  margin-right: 40px;
}

.chat-form .input-cont .form-control {
  width: 100% !important;
  margin-bottom: 0px;
}

.chat-form .input-cont input {
  border: 1px solid #ddd;
  width: 100% !important;
  margin-top: 0;
}

.chat-form .input-cont input {
  background-color: #fff !important;
}

.chat-form .input-cont input:focus {
  border: 1px solid #4b8df9 !important;
}

.chat-form .btn-cont {
  margin-top: -42px;
  position: relative;
  float: right;
  width: 44px;
}

.chat-form .btn-cont .arrow {
  position: absolute;
  top: 17px;
  right: 43px;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #4d90fe;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.chat-form .btn-cont:hover .arrow {
  border-right-color: #0362fd;
}

.chat-form .btn-cont:hover .btn {
  background-color: #0362fd;
}

.chat-form .btn-cont .btn {
  margin-top: 8px;
}

/***
  System feeds
  ***/
.feeds {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.feeds li {
  background-color: #fafafa;
  margin-bottom: 7px;
}

.feeds li:before,
.feeds li:after {
  display: table;
  line-height: 0;
  content: "";
}

.feeds li:after {
  clear: both;
}

.feeds li:last-child {
  margin-bottom: 0px;
}

.feeds .col1 {
  float: left;
  width: 100%;
  clear: both;
}

.feeds .col2 {
  float: left;
  width: 75px;
  margin-left: -75px;
}

.feeds .col1 .cont {
  float: left;
  margin-right: 75px;
  overflow: hidden;
}

.feeds .col1 .cont .cont-col1 {
  float: left;
  margin-right: -100%;
}

.feeds .col1 .cont .cont-col1 .label {
  display: inline-block;
  padding: 5px 4px 6px 5px;
  vertical-align: middle;
  text-align: center;
}

.feeds .col1 .cont .cont-col1 .label>i {
  text-align: center;
  font-size: 14px;
}

.feeds .col1 .cont .cont-col2 {
  float: left;
  width: 100%;
}

.feeds .col1 .cont .cont-col2 .desc {
  margin-left: 35px;
  padding-top: 4px;
  padding-bottom: 5px;
  overflow: hidden;
}

.feeds .col2 .date {
  padding: 4px 9px 5px 4px;
  text-align: right;
  font-style: italic;
  color: #c1cbd0;
}

/***
  Users
  ***/
.user-info {
  margin-bottom: 10px !important;
}

.user-info img {
  float: left;
  margin-right: 5px;
}

.user-info .details {
  display: inline-block;
}

.user-info .label {
  font-weight: 300;
  font-size: 11px;
}

/***
  Accordions
  ***/
.accordion-heading {
  background: #eee;
}

.accordion-heading a {
  text-decoration: none;
}

.accordion-heading a:hover {
  text-decoration: none;
}

/***
  Vertical inline menu
  ***/
.ver-inline-menu {
  padding: 0;
  margin: 0;
  list-style: none;
}

.ver-inline-menu li {
  position: relative;
  margin-bottom: 1px;
}

.ver-inline-menu li i {
  width: 37px;
  height: 37px;
  display: inline-block;
  color: #b9cbd5;
  font-size: 15px;
  padding: 12px 10px 10px 8px;
  margin: 0 8px 0 0;
  text-align: center;
  background: #e0eaf0 !important;
}

.ver-inline-menu li a {
  font-size: 13px;
  color: #557386;
  display: block;
  background: #f0f6fa;
  border-left: solid 2px #c4d5df;
}

.ver-inline-menu li:hover a,
.ver-inline-menu li:hover i {
  background: #e0eaf0;
  text-decoration: none;
}

.ver-inline-menu li:hover i {
  color: #fff;
  background: #c4d5df !important;
}

.ver-inline-menu li.active a,
.ver-inline-menu li:hover a {
  font-size: 13px;
}

.ver-inline-menu li.active a {
  border-left: solid 2px #0c91e5;
}

.ver-inline-menu li.active a,
.ver-inline-menu li.active i {
  color: #fff;
  background: #169ef4;
  text-decoration: none;
}

.ver-inline-menu li.active i {
  background: #0c91e5 !important;
}

.ver-inline-menu li.active:after {
  content: '';
  display: inline-block;
  border-bottom: 6px solid transparent;
  border-top: 6px solid transparent;
  border-left: 6px solid #169ef4;
  position: absolute;
  top: 12px;
  right: -5px;
}

/***
  Custom tabs
  ***/
.nav-tabs>li>a>.badge,
.nav-pills>li>a>.badge {
  margin-top: -3px;
}

.nav-tabs>li>a,
.nav-pills>li>a {
  font-size: 14px;
}

.nav-tabs-sm>li>a,
.nav-pills-sm>li>a {
  font-size: 13px;
}

.tabbable-custom {
  margin-bottom: 15px;
  padding: 0px;
  overflow: hidden;
}

.tabbable-custom>.nav-tabs {
  border: none;
  margin: 0px;
}

.tabbable-custom>.tab-content {
  background-color: #fff;
  border: 1px solid #ddd;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  padding: 10px;
}

.tabbable-custom.nav-justified .tab-content {
  margin-top: -1px;
}

.tabs-below.tabbable-custom.nav-justified .tab-content {
  margin-top: 0px;
  margin-bottom: -2px;
}

.tabbable-custom.boxless>.tab-content {
  padding: 15px 0;
  border-left: none;
  border-right: none;
  border-bottom: none;
}

.tabbable-custom .nav-tabs>li {
  margin-right: 2px;
  border-top: 2px solid transparent;
}

.tabbable-custom .nav-tabs>li>a {
  margin-right: 0;
}

.tabbable-custom .nav-tabs>li>a:hover {
  background: none;
  border-color: transparent;
}

.tabbable-custom .nav-tabs>li.active {
  border-top: 3px solid #d12610;
  margin-top: 0;
  position: relative;
}

.tabbable-custom .nav-tabs>li.active>a {
  border-top: none;
  font-weight: 400;
}

.tabbable-custom .nav-tabs>li.active>a:hover {
  border-top: none;
  background: #fff;
  border-color: #d4d4d4 #d4d4d4 transparent;
}

.tabbable-custom .nav-tabs>li {
  margin-right: 2px;
  border-top: 2px solid transparent;
}

/* below tabs */

.tabs-below.tabbable-custom .nav-tabs>li>a {
  border-top: none;
  border-bottom: 2px solid transparent;
  margin-top: -1px;
}

.tabs-below.tabbable-custom .nav-tabs>li.active {
  border-top: none;
  border-bottom: 3px solid #d12610;
  margin-bottom: 0;
  position: relative;
}

.tabs-below.tabbable-custom .nav-tabs>li.active>a {
  border-bottom: none
}

.tabs-below.tabbable-custom .nav-tabs>li.active>a:hover {
  background: #fff;
  border-color: #d4d4d4 #d4d4d4 transparent;
}

/*full width tabs with bigger titles */
.tabbable-custom.tabbable-full-width>.tab-content {
  padding: 15px 0;
  border-left: none;
  border-right: none;
  border-bottom: none;
}

.tabbable-custom.tabbable-full-width .nav-tabs>li>a {
  color: #424242;
  font-size: 15px;
  padding: 9px 15px;
}

/***
  Custom portlet tabs
  ***/

.portlet-tabs>.nav-tabs {
  position: relative;
  top: -41px;
  margin-right: 10px;
  overflow: hidden;
}

.portlet-tabs>.nav-tabs>li {
  float: right;
}

.portlet-tabs>.nav-tabs {
  border-bottom: none;
}

.portlet-tabs>.nav-tabs>li>a {
  color: #fff;
  padding-top: 8px;
  padding-bottom: 10px;
  line-height: 16px;
  margin-top: 6px;
  margin-left: 0px;
  margin-right: 0px;
  border-left: 0;
  border-right: 0;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
}

.portlet-tabs>.nav-tabs>li:last-child>a {
  border-right: 0;
}

.portlet-tabs>.nav-tabs>li {
  margin-left: 1px;
}

.portlet-tabs>.nav-tabs>li.active {
  color: #333;
  border-top-color: transparent;
}

.portlet-tabs>.nav-tabs>li.active>a {
  margin-bottom: 0px;
  border-bottom: 0;
  margin-left: 0px;
  margin-right: 0px;
  border-left: 0;
  border-right: 0;
  border-top-color: transparent !important;
}

.portlet-tabs>.nav-tabs>li>a:hover {
  color: #333;
  margin-bottom: 0;
  border-bottom-color: transparent;
  margin-left: 0;
  margin-right: 0;
  border-left: 0;
  border-right: 0;
  border-top-color: transparent;
  background-color: #fff;
}

.portlet-tabs>.nav-tabs>.active>a {
  color: #555555;
  cursor: default;
  background-color: #fff;
}

.portlet-tabs>.nav-tabs>.active>a:hover {
  background-color: #fff !important;
}

.portlet-tabs>.tab-content {
  padding: 10px !important;
  margin: 0px;
  margin-top: -50px !important;
}

.portlet.tabbable .portlet-body {
  padding: 0px;
}

.tab-pane>p:last-child {
  margin-bottom: 0px;
}

/* reverse aligned tabs */

.tabs-reversed>li {
  float: right;
}

.tabs-reversed>li,
.tabs-reversed>li>a {
  margin-right: 0;
}

/***
  Dashboard container
  ***/

#dashboard {
  overflow: hidden;
}

/***
  Dashboard stats
  ***/
.dashboard-stat {
  margin-bottom: 25px;
}

.portlet .dashboard-stat:last-child {
  margin-bottom: 0;
}

.dashboard-stat:before,
.dashboard-stat:after {
  display: table;
  line-height: 0;
  content: "";
}

.dashboard-stat:after {
  clear: both;
}

.dashboard-stat .visual {
  width: 80px;
  height: 80px;
  display: block;
  float: left;
  padding-top: 10px;
  padding-left: 15px;
  margin-bottom: 10px;
}

.dashboard-stat .visual i {
  font-size: 65px;
  line-height: 65px;
  color: #fff;
}

.dashboard-stat .visual {
  font-size: 35px;
  line-height: 35px;
}

@media (min-width: 992px) and (max-width: 1024px) {

  .dashboard-stat .visual i {
    font-size: 28px;
    line-height: 28px;
  }

}

.dashboard-stat .details {
  position: absolute;
  right: 15px;
  padding-right: 10px;
}

.dashboard-stat .details .number {
  padding-top: 15px;
  text-align: right;
  font-size: 34px;
  line-height: 34px;
  letter-spacing: -1px;
  margin-bottom: 5px;
  font-weight: 300;
  color: #fff;
}

.dashboard-stat .details .desc {
  text-align: right;
  font-size: 16px;
  letter-spacing: 0px;
  font-weight: 300;
  color: #fff;
}

.dashboard-stat .more {
  clear: both;
  display: block;
  padding: 5px 10px 5px 10px;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 11px;
  color: #fff;
  opacity: 0.7;
  filter: alpha(opacity=70);
}

.dashboard-stat .more:hover {
  text-decoration: none;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat .more>i {
  display: inline-block;
  margin-top: 1px;
  float: right;
}

.dashboard-stat.blue {
  background-color: #27a9e3;
}

.dashboard-stat.blue .more {
  background-color: #208dbe;
}

.dashboard-stat.green {
  background-color: #28b779;
}

.dashboard-stat.green .more {
  background-color: #10a062;
}

.dashboard-stat.red {
  background-color: #e7191b;
}

.dashboard-stat.red .more {
  background-color: #bc0d0e;
}

.dashboard-stat.yellow {
  background-color: #ffb848;
}

.dashboard-stat.yellow .more {
  background-color: #cb871b;
}

.dashboard-stat.purple {
  background-color: #852b99;
}

.dashboard-stat.purple .more {
  background-color: #6e1881;
}

/***
  Text Stats
  ***/

.text-stat h3 {
  margin-top: 5px;
  margin-bottom: 0px;
  font-size: 18px;
}

.text-stat span {
  font-size: 12px;
  text-transform: uppercase;
}

@media (max-width: 767px) {

  .text-stat {
    margin-top: 20px;
  }

}

/***
  Tiles(new in v1.1.1)
  ***/

.tiles {
  margin-right: -10px;
}

.tiles:before,
.tiles:after {
  display: table;
  content: " ";
}

.tiles:after {
  clear: both;
}

.tile {
  display: block;
  letter-spacing: 0.02em;
  float: left;
  height: 135px;
  width: 135px !important;
  cursor: pointer;
  text-decoration: none;
  color: #ffffff;
  position: relative;
  font-weight: 300;
  font-size: 12px;
  letter-spacing: 0.02em;
  line-height: 20px;
  overflow: hidden;
  border: 4px solid transparent;
  margin: 0 10px 10px 0;
}

.tile:after,
.tile:before {
  content: "";
  float: left;
}

.tile.double {
  width: 280px !important;
}

.tile.double-down {
  height: 280px !important;
}

.tile:active,
.tile.selected {
  border-color: #ccc !important;
}

.tile:hover {
  border-color: #aaa !important;
}

.tile.selected .corner:after {
  content: "";
  display: inline-block;
  border-left: 40px solid transparent;
  border-bottom: 40px solid transparent;
  border-right: 40px solid #ccc;
  position: absolute;
  top: -3px;
  right: -3px;
}

.tile.selected .check:after {
  content: "";
  font-family: FontAwesome;
  font-size: 13px;
  content: "\f00c";
  display: inline-block;
  position: absolute;
  top: 2px;
  right: 2px;
}

.tile * {
  color: #ffffff;
}

.tile .tile-body {
  height: 100%;
  vertical-align: top;
  padding: 10px 10px;
  overflow: hidden;
  position: relative;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
  color: #ffffff;
  margin-bottom: 10px;
}

.tile .tile-body img {
  float: left;
  margin-right: 10px;
}

.tile .tile-body img.pull-right {
  float: right !important;
  margin-left: 10px;
  margin-right: 0px;
}

.tile .tile-body .content {
  display: inline-block;
}

.tile .tile-body>i {
  margin-top: 17px;
  display: block;
  font-size: 56px;
  line-height: 56px;
  text-align: center;
}


.tile.double-down i {
  margin-top: 95px;
}

.tile .tile-body h1,
.tile .tile-body h2,
.tile .tile-body h3,
.tile .tile-body h4,
.tile .tile-body h5,
.tile .tile-body h6,
.tile .tile-body p {
  padding: 0;
  margin: 0;
  line-height: 14px;
}

.tile .tile-body h3,
.tile .tile-body h4 {
  margin-bottom: 5px;
}

.tile .tile-body h1:hover,
.tile .tile-body h2:hover,
.tile .tile-body h3:hover,
.tile .tile-body h4:hover,
.tile .tile-body h5:hover,
.tile .tile-body h6:hover,
.tile .tile-body p:hover {
  color: #ffffff;
}

.tile .tile-body p {
  font-weight: 400;
  font-size: 13px;
  color: #000000;
  color: #ffffff;
  line-height: 20px;
  overflow: hidden;
}

.tile .tile-body p:hover {
  color: rgba(0, 0, 0, 0.8);
}

.tile .tile-body p:active {
  color: rgba(0, 0, 0, 0.4);
}

.tile .tile-body p:hover {
  color: #ffffff;
}

.tile.icon>.tile-body {
  padding: 0;
}

.tile .tile-object {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 30px;
  background-color: transparent;
  *zoom: 1;
}

.tile .tile-object:before,
.tile .tile-object:after {
  display: table;
  content: "";
}

.tile .tile-object:after {
  clear: both;
}

.tile .tile-object>.name {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: 5px;
  margin-left: 10px;
  margin-right: 15px;
  font-weight: 400;
  font-size: 13px;
  color: #ffffff;
}

.tile .tile-object>.name>i {
  vertical-align: middle;
  display: block;
  font-size: 24px;
  height: 18px;
  width: 24px;
}

.tile .tile-object>.number {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 0;
  color: #ffffff;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.01em;
  line-height: 14px;
  margin-bottom: 8px;
  margin-right: 10px;
}

.tile.image>.tile-body {
  padding: 0 !important;
}

.tile.image>.tile-body>img {
  width: 100%;
  height: auto;
  min-height: 100%;
  max-width: 100%;
}

.tile.image .tile-body h3 {
  display: inline-block;
}

/***
  Theme Panel
  ***/

.theme-panel {
  width: 400px;
  margin-top: -20px;
  margin-right: 1px;
  z-index: 999;
  float: right;
  position: relative;
}

.theme-panel>.toggler {
  top: 4px;
  right: 0;
  padding: 20px;
  cursor: pointer;
  position: absolute;
  background: #c9c9c9 url(assets/img/icon-color.png) center no-repeat;
}

.theme-panel>.toggler:hover {
  background-color: #3d3d3d !important;
}

.theme-panel>.toggler-close {
  display: none;
  top: 4px;
  right: 0;
  padding: 20px;
  cursor: pointer;
  position: absolute;
  background: #3d3d3d url(assets/img/icon-color-close.png) center no-repeat !important;
}

.theme-panel>.toggler-close:hover {
  background-color: #222 !important;
}

.theme-panel>.theme-options {
  top: 4px;
  right: 40px;
  display: none;
  position: absolute;
  background: #3d3d3d;
}

.theme-panel>.theme-options>.theme-option {
  color: #cfcfcf;
  padding: 15px;
  border-top: 1px solid #585858;
  margin-top: 0px;
  margin-bottom: 0px;
}

.theme-panel>.theme-options>.theme-option.theme-colors {
  border-top: 0;
}

.theme-panel>.theme-options>.theme-option>span {
  text-transform: uppercase;
  display: inline-block;
  width: 138px;
  font-size: 14px;
}

.theme-panel>.theme-options>.theme-option.theme-colors>span {
  display: block;
  width: auto;
}

.theme-panel>.theme-options>.theme-option>select.form-control {
  display: inline;
  width: 100px;
  text-transform: lowercase;
}

.theme-panel>.theme-options>.theme-option.theme-colors>ul {
  list-style: none;
  padding: 0;
  display: block;
  margin-bottom: 1px !important;
  margin-top: 10px;
}

.theme-panel>.theme-options>.theme-option.theme-colors>ul>li {
  width: 37px;
  height: 37px;
  margin: 0 4px;
  cursor: pointer;
  list-style: none;
  float: left;
  border: solid 1px #707070;
}

.theme-panel>.theme-options>.theme-option.theme-colors>ul>li:first-child {
  margin-left: 0;
}

.theme-panel>.theme-options>.theme-option.theme-colors>ul>li:hover,
.theme-panel>.theme-options>.theme-option.theme-colors>ul>li.current {
  border: solid 2px #ebebeb;
}

.theme-panel>.theme-options>.theme-option.theme-colors>ul>li.color-black {
  background: #333438;
}

.theme-panel>.theme-options>.theme-option.theme-colors>ul>li.color-grey {
  background: #6d6d6d;
}

.theme-panel>.theme-options>.theme-option.theme-colors>ul>li.color-blue {
  background: #124f94;
}

.theme-panel>.theme-options>.theme-option.theme-colors>ul>li.color-brown {
  background: #623f18;
}

.theme-panel>.theme-options>.theme-option.theme-colors>ul>li.color-purple {
  background: #701584;
}

.theme-panel>.theme-options>.theme-option.theme-colors>ul>li.color-white {
  background: #fff;
}

/***
  Top bar menu
  ***/

/* enable arrow for dropdown menu */
.header.navbar .nav>li>.dropdown-menu:before {
  position: absolute;
  top: -7px;
  right: 9px;
  display: inline-block !important;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-left: 7px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: '';
}

.header.navbar .nav>li>.dropdown-menu:after {
  position: absolute;
  top: -6px;
  right: 10px;
  display: inline-block !important;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
  content: '';
}

/***
  Mega Menu(new in v1.6)
  ***/

.mega-menu .nav,
.mega-menu .collapse,
.mega-menu .mega-menu-dropup,
.mega-menu .mega-menu-dropdown {
  position: static;
}

.mega-menu .container {
  position: relative;
}

.mega-menu .mega-menu-dropdown .dropdown-menu {
  left: auto;
  width: auto;
}

.mega-menu .nav.navbar-right .dropdown-menu {
  left: auto;
  right: 0;
}

.mega-menu .mega-menu-content {
  padding: 10px;
  margin: 0;
}

.mega-menu .mega-menu-full .dropdown-menu {
  left: 20px;
  right: 20px;
}

.mega-menu-responsive-content {
  padding: 10px 15px 10px 60px;
}

.page-boxed .mega-menu .mega-menu-dropdown .dropdown-menu {
  top: 42px;
}

.page-boxed .mega-menu .mega-menu-dropdown.mega-menu-full .dropdown-menu {
  margin: 0;
  padding: 0;
  left: 18px;
  right: 18px;
}

.mega-menu .mega-menu-submenu {
  width: auto !important;
  padding: 0px 15px !important;
  margin: 0 !important;
}

.mega-menu .mega-menu-submenu:last-child {
  border-right: 0;
}

.mega-menu .mega-menu-submenu li>h3 {
  font-size: 14px;
  margin-top: 10px;
  padding-left: 5px;
}

.mega-menu .mega-menu-submenu li {
  padding: 2px !important;
  margin: 0 !important;
  list-style: none;
}

.mega-menu .mega-menu-submenu li>a {
  padding: 5px !important;
  margin: 0 !important;
}

/***
  Horezantal Menu(new in v1.2)
  ***/

.header.navbar .hor-menu {
  margin: 0;
  float: left;
}

.header.navbar .hor-menu ul.nav li>a {
  font-size: 14px;
  padding: 11px 10px;
}

.header.navbar .hor-menu ul.nav li.current .selected,
.header.navbar .hor-menu ul.nav li.active .selected {
  left: 50%;
  bottom: 0;
  position: absolute;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #e02222;
  display: inline-block;
  margin: 0;
  width: 0px;
  height: 0px;
  margin-left: -7px;
  margin-bottom: -6px;
}

/*drop-down*/
.header.navbar .hor-menu .dropdown-menu {
  margin-top: 0;
  border: none;
  box-shadow: none;
}

.header.navbar .hor-menu .classic-menu-dropdown .dropdown-submenu>.dropdown-menu {
  top: 0;
}

.header.navbar .hor-menu .classic-menu-dropdown .dropdown-submenu>a:after {
  top: 8px;
  margin-right: 0px;
}

.header.navbar .hor-menu .classic-menu-dropdown .dropdown-menu li>a {
  padding: 7px 18px !important;
  margin-bottom: 1px;
}

.header.navbar .hor-menu .classic-menu-dropdown .dropdown-menu .arrow {
  display: none;
}

.header.navbar .hor-menu .classic-menu-dropdown .dropdown-menu li>a:hover,
.header.navbar .hor-menu .classic-menu-dropdown .dropdown-menu li:hover>a,
.header.navbar .hor-menu .classic-menu-dropdown .dropdown-menu li.active>a {
  filter: none !important;
}

.header.navbar .hor-menu .nav>li>.dropdown-menu:after,
.header.navbar .hor-menu .nav>li>.dropdown-menu:before {
  border-bottom: none !important;
}

/*search*/
.header.navbar .hor-menu .hor-menu-search-form-toggler {
  display: inline-block;
  padding: 12px 22px 12px 22px !important;
  cursor: pointer;
  background: url(assets/img/hor-menu-search.png) no-repeat center;
}

.header.navbar .hor-menu .hor-menu-search-form-toggler:hover {
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.header.navbar .hor-menu a.hor-menu-search-form-toggler-close {
  display: none;
}

.header.navbar .hor-menu .search-form {
  margin: 0;
  top: 42px;
  right: 0px;
  padding: 0 4px;
  display: none;
  z-index: 999;
  position: absolute;
}

.header.navbar .hor-menu .search-form .btn {
  padding: 7px 20px;
  height: 32px;
  width: 10px;
  display: inline-block;
}

.header.navbar .hor-menu .search-form .btn:hover {
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.header.navbar .hor-menu .search-form form {
  margin-bottom: 0;
}

.header.navbar .hor-menu .search-form form input {
  background: none;
  width: 200px;
  border: none;
  margin-top: 6px;
}

/***
  Top News Blocks(new in v1.2.2)
  ***/
.top-news {
  color: #fff;
  margin: 8px 0;
}

.top-news a,
.top-news em,
.top-news span {
  display: block;
  text-align: left;
}

.top-news a {
  padding: 10px;
  position: relative;
  margin-bottom: 10px;
}

.top-news a .top-news-icon {
  right: 8px;
  bottom: 15px;
  opacity: 0.3;
  font-size: 35px;
  position: absolute;
  filter: alpha(opacity=30);
  /*For IE8*/
}

.top-news em {
  margin-bottom: 0;
  font-style: normal;
}

.top-news span {
  font-size: 18px;
  margin-bottom: 5px;
}

/***
  Block Images(new in v1.2.2)
  ***/
.blog-images {
  margin-bottom: 0;
}

.blog-images li {
  padding: 0;
  margin: 0;
  display: inline;
}

.blog-images li a:hover {
  text-decoration: none;
}

.blog-images li img {
  width: 50px;
  height: 50px;
  opacity: 0.6;
  margin: 0 2px 8px;
}

.blog-images li img:hover {
  opacity: 1;
  box-shadow: 0 0 0 4px #72c02c;
  transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -webkit-transition: all 0.4s ease-in-out 0s;
}

/*Sidebar Tags*/
ul.sidebar-tags a {
  color: #555;
  font-size: 12px;
  padding: 3px 5px;
  background: #f7f7f7;
  margin: 0 2px 5px 0;
  display: inline-block;
}

ul.sidebar-tags a:hover,
ul.sidebar-tags a:hover i {
  background: #EEE;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

ul.sidebar-tags a i {
  color: #777;
}

ul.sidebar-tags li {
  padding: 0;
}

/***
  Social Icons(new in v1.2.2)
  ***/
.social-icons {
  padding: 0;
  margin: 0;
}

.social-icons:after,
.social-icons:before {
  content: "";
  display: table;
}

.social-icons:after {
  clear: both;
}

.social-icons li {
  float: left;
  display: inline;
  list-style: none;
  margin-right: 5px;
  margin-bottom: 5px;
  text-indent: -9999px;
}

.social-icons li a,
a.social-icon {
  width: 28px;
  height: 28px;
  display: block;
  background-position: 0 0;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.social-icons li:hover a {
  background-position: 0 -38px;
}

.social-icons-color li a {
  opacity: 0.7;
  background-position: 0 -38px !important;
}

.social-icons-color li a:hover {
  opacity: 1;
}

.social-icons .amazon {
  background: url(assets/img/social/amazon.png) no-repeat;
}

.social-icons .behance {
  background: url(assets/img/social/behance.png) no-repeat;
}

.social-icons .blogger {
  background: url(assets/img/social/blogger.png) no-repeat;
}

.social-icons .deviantart {
  background: url(assets/img/social/deviantart.png) no-repeat;
}

.social-icons .dribbble {
  background: url(assets/img/social/dribbble.png) no-repeat;
}

.social-icons .dropbox {
  background: url(assets/img/social/dropbox.png) no-repeat;
}

.social-icons .evernote {
  background: url(assets/img/social/evernote.png) no-repeat;
}

.social-icons .facebook {
  background: url(assets/img/social/facebook.png) no-repeat;
}

.social-icons .forrst {
  background: url(assets/img/social/forrst.png) no-repeat;
}

.social-icons .github {
  background: url(assets/img/social/github.png) no-repeat;
}

.social-icons .googleplus {
  background: url(assets/img/social/googleplus.png) no-repeat;
}

.social-icons .jolicloud {
  background: url(assets/img/social/jolicloud.png) no-repeat;
}

.social-icons .last-fm {
  background: url(assets/img/social/last-fm.png) no-repeat;
}

.social-icons .linkedin {
  background: url(assets/img/social/linkedin.png) no-repeat;
}

.social-icons .picasa {
  background: url(assets/img/social/picasa.png) no-repeat;
}

.social-icons .pintrest {
  background: url(assets/img/social/pintrest.png) no-repeat;
}

.social-icons .rss {
  background: url(assets/img/social/rss.png) no-repeat;
}

.social-icons .skype {
  background: url(assets/img/social/skype.png) no-repeat;
}

.social-icons .spotify {
  background: url(assets/img/social/spotify.png) no-repeat;
}

.social-icons .stumbleupon {
  background: url(assets/img/social/stumbleupon.png) no-repeat;
}

.social-icons .tumblr {
  background: url(assets/img/social/tumblr.png) no-repeat;
}

.social-icons .twitter {
  background: url(assets/img/social/twitter.png) no-repeat;
}

.social-icons .vimeo {
  background: url(assets/img/social/vimeo.png) no-repeat;
}

.social-icons .wordpress {
  background: url(assets/img/social/wordpress.png) no-repeat;
}

.social-icons .xing {
  background: url(assets/img/social/xing.png) no-repeat;
}

.social-icons .yahoo {
  background: url(assets/img/social/yahoo.png) no-repeat;
}

.social-icons .youtube {
  background: url(assets/img/social/youtube.png) no-repeat;
}

.social-icons .vk {
  background: url(assets/img/social/vk.png) no-repeat;
}

.social-icons .instagram {
  background: url(assets/img/social/instagram.png) no-repeat;
}

.social-icons .reddit {
  background: url(assets/img/social/reddit.png) no-repeat;
}

.social-icons .aboutme {
  background: url(assets/img/social/aboutme.png) no-repeat;
}

.social-icons .flickr {
  background: url(assets/img/social/flickr.png) no-repeat;
}

.social-icons .foursquare {
  background: url(assets/img/social/foursquare.png) no-repeat;
}

.social-icons .gravatar {
  background: url(assets/img/social/gravatar.png) no-repeat;
}

.social-icons .klout {
  background: url(assets/img/social/klout.png) no-repeat;
}

.social-icons .myspace {
  background: url(assets/img/social/myspace.png) no-repeat;
}

.social-icons .quora {
  background: url(assets/img/social/quora.png) no-repeat;
}

/***
  Inline Social Icons
  ***/

.social-icon {
  display: inline-block !important;
  width: 28px;
  height: 28px;
  background-position: 0 0;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.social-icon.amazon {
  background: url(assets/img/social/amazon.png) no-repeat;
}

.social-icon.behance {
  background: url(assets/img/social/behance.png) no-repeat;
}

.social-icon.blogger {
  background: url(assets/img/social/blogger.png) no-repeat;
}

.social-icon.deviantart {
  background: url(assets/img/social/deviantart.png) no-repeat;
}

.social-icon.dribbble {
  background: url(assets/img/social/dribbble.png) no-repeat;
}

.social-icon.dropbox {
  background: url(assets/img/social/dropbox.png) no-repeat;
}

.social-icon.evernote {
  background: url(assets/img/social/evernote.png) no-repeat;
}

.social-icon.facebook {
  background: url(assets/img/social/facebook.png) no-repeat;
}

.social-icon.forrst {
  background: url(assets/img/social/forrst.png) no-repeat;
}

.social-icon.github {
  background: url(assets/img/social/github.png) no-repeat;
}

.social-icon.googleplus {
  background: url(assets/img/social/googleplus.png) no-repeat;
}

.social-icon.jolicloud {
  background: url(assets/img/social/jolicloud.png) no-repeat;
}

.social-icon.last-fm {
  background: url(assets/img/social/last-fm.png) no-repeat;
}

.social-icon.linkedin {
  background: url(assets/img/social/linkedin.png) no-repeat;
}

.social-icon.picasa {
  background: url(assets/img/social/picasa.png) no-repeat;
}

.social-icon.pintrest {
  background: url(assets/img/social/pintrest.png) no-repeat;
}

.social-icon.rss {
  background: url(assets/img/social/rss.png) no-repeat;
}

.social-icon.skype {
  background: url(assets/img/social/skype.png) no-repeat;
}

.social-icon.spotify {
  background: url(assets/img/social/spotify.png) no-repeat;
}

.social-icon.stumbleupon {
  background: url(assets/img/social/stumbleupon.png) no-repeat;
}

.social-icon.tumblr {
  background: url(assets/img/social/tumblr.png) no-repeat;
}

.social-icon.twitter {
  background: url(assets/img/social/twitter.png) no-repeat;
}

.social-icon.vimeo {
  background: url(assets/img/social/vimeo.png) no-repeat;
}

.social-icon.wordpress {
  background: url(assets/img/social/wordpress.png) no-repeat;
}

.social-icon.xing {
  background: url(assets/img/social/xing.png) no-repeat;
}

.social-icon.yahoo {
  background: url(assets/img/social/yahoo.png) no-repeat;
}

.social-icon.youtube {
  background: url(assets/img/social/youtube.png) no-repeat;
}

.social-icon.vk {
  background: url(assets/img/social/vk.png) no-repeat;
}

.social-icon.instagram {
  background: url(assets/img/social/instagram.png) no-repeat;
}

.social-icon.reddit {
  background: url(assets/img/social/reddit.png) no-repeat;
}

.social-icon.aboutme {
  background: url(assets/img/social/aboutme.png) no-repeat;
}

.social-icon.flickr {
  background: url(assets/img/social/flickr.png) no-repeat;
}

.social-icon.foursquare {
  background: url(assets/img/social/foursquare.png) no-repeat;
}

.social-icon.gravatar {
  background: url(assets/img/social/gravatar.png) no-repeat;
}

.social-icon.klout {
  background: url(assets/img/social/klout.png) no-repeat;
}

.social-icon.myspace {
  background: url(assets/img/social/myspace.png) no-repeat;
}

.social-icon.quora {
  background: url(assets/img/social/quora.png) no-repeat;
}

.social-icon:hover {
  background-position: 0 -38px;
}

.social-icon-color {
  opacity: 0.7;
  background-position: 0 -38px !important;
}

.social-icon-color:hover {
  opacity: 1;
}

/***
  Notes
  ***/

/* Common styles for all types */
.note {
  margin: 0 0 20px 0;
  padding: 15px 30px 15px 15px;
  border-left: 5px solid #eee;
}

.note h1,
.note h2,
.note h3,
.note h4 {
  margin-top: 0;
}

.note p:last-child {
  margin-bottom: 0;
}

.note code,
.note .highlight {
  background-color: #fff;
}

/* Variations */
.note-danger {
  background-color: #FAEAE6;
  border-color: #ed4e2a;
}

.note-warning {
  background-color: #FCF3E1;
  border-color: #fcb322;
}

.note-info {
  background-color: #E8F6FC;
  border-color: #57b5e3;
}

.note-success {
  background-color: #EBFCEE;
  border-color: #3cc051;
}

/***
  Demo Utils
  ***/
.scrollspy-example {
  position: relative;
  height: 200px;
  margin-top: 10px;
  overflow: auto;
}

.util-btn-margin-bottom-5 .btn {
  margin-bottom: 5px !important;
}

.util-btn-group-margin-bottom-5 .btn-group {
  margin-bottom: 5px !important;
}

.fontawesome-demo i {
  font-size: 18px;
}

.fontawesome-demo li {
  padding-top: 5px;
  padding-bottom: 5px;
}

.glyphicons-demo ul {
  padding-left: 0;
  padding-bottom: 1px;
  margin-bottom: 20px;
  list-style: none;
  overflow: hidden;
}

.bs-glyphicons {
  padding-left: 0;
  padding-bottom: 1px;
  margin-bottom: 20px;
  list-style: none;
  overflow: hidden;
}

.glyphicons-demo ul li {
  float: left;
  width: 25%;
  height: 115px;
  padding: 10px;
  margin: 0 -1px -1px 0;
  font-size: 12px;
  line-height: 1.4;
  text-align: center;
  border: 1px solid #ddd;
}

.glyphicons-demo .glyphicon {
  display: block;
  margin: 5px auto 10px;
  font-size: 24px;
}

.glyphicons-demo ul li:hover {
  background-color: rgba(86, 61, 124, .1);
}

@media (min-width: 768px) {
  .glyphicons-demo ul li {
    width: 12.5%;
  }
}

/***
  Forms 
  ****/

.static-info {
  margin-bottom: 10px;
}

.static-info .name {
  font-size: 14px;
}

.static-info .value {
  font-size: 14px;
  font-weight: 600;
}

.static-info.align-reverse .name,
.static-info.align-reverse .value {
  text-align: right;
}

input.placeholder,
textarea.placeholder {
  color: #aaa !important;
}

.help-block {
  margin-top: 5px;
  margin-bottom: 5px;
}

.form-inline input {
  margin-bottom: 0px !important;
}

.control-label {
  margin-top: 2px;
}

.form-control-static {
  font-size: 14px;
  padding-top: 7px;
}

.control-label .required {
  color: #e02222;
  font-size: 12px;
  padding-left: 2px;
}

.switch-wrapper {
  display: inline-block;
}

.form {
  padding: 0 !important;
}

.form-body {
  padding: 10px;
}

.form-actions {
  padding: 20px 10px;
  margin-top: 20px;
  background-color: #f5f5f5;
  border-top: 1px solid #e5e5e5;
  *zoom: 1;
}

.form-actions.nobg {
  background-color: transparent;
}

.form-actions.top {
  margin-top: 0;
  margin-bottom: 20px;
  border-top: 0;
  border-bottom: 1px solid #e5e5e5;
}

.form-actions.fluid {
  padding: 20px 0;
}

.form-actions.fluid>[class^="col-"] {
  padding-left: 13px;
}

.form-actions:before,
.form-actions:after {
  display: table;
  line-height: 0;
  content: "";
}

.form-actions:after {
  clear: both;
}

.form-section {
  margin: 30px 0px 25px 0px;
  padding-bottom: 5px;
  border-bottom: 1px solid #eee;
}

.form .form-section:first-child {
  margin-top: 5px;
}

.help-inline {
  font-size: 13px;
  color: #737373;
  display: inline-block;
  padding: 5px;
}

/* left, right aligned form actions */
.form-actions.right {
  padding-left: 0;
  padding-right: 10px;
  text-align: right;
}

.form-actions.left {
  padding-left: 10px;
  padding-right: 0;
  text-align: left;
}

/* Checkboxes */
.form-group .checkbox {
  padding-left: 0;
}

.checkbox-list>label {
  display: block;
}

.checkbox-list>label.checkbox-inline {
  display: inline-block;
}

.checkbox-list>label.checkbox-inline:first-child {
  padding-left: 0;
}

/* Radios */

.radio-list>label {
  display: block;
}

.radio-list>label.radio-inline {
  display: inline-block;
}

.radio-list>label.radio-inline:first-child {
  padding-left: 0;
}

.form-horizontal .radio-list .radio {
  padding-top: 1px;
}

.form-horizontal .radio-list>label {
  margin-bottom: 0;
}

.form-horizontal .radio>span {
  margin-top: 2px;
}

/* Rows seperated form layout */
.form-row-seperated .form-group {
  margin: 0;
  border-bottom: 1px solid #efefef;
  padding: 10px 0px 10px 0px;
}

.form-row-seperated .form-group.last {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 10px;
}

.form-row-seperated .form-actions {
  margin-top: 0;
}

.form-row-seperated .form-body {
  padding: 0;
  margin-top: 0;
}

.form-row-seperated .help-block {
  margin-bottom: 0;
}

/* form bordered */
.form-bordered .form-body {
  margin: 0;
  padding: 0;
}

.form-bordered .form-actions {
  margin-top: 0;
}

.form-bordered .form-group {
  margin: 0;
  border-bottom: 1px solid #efefef;
}

.form-bordered .form-group.last {
  border-bottom: 0;
}

.form-bordered .help-block {
  margin-bottom: 0;
}

.form-bordered .control-label {
  padding-top: 16px;
}

.form-bordered .form-group>div {
  padding: 10px;
  border-left: 1px solid #efefef;
}

.form-bordered .form-actions.fluid>.row>div {
  padding-left: 10px;
}

.form-horizontal.form-bordered.form-row-stripped .form-group:nth-child(even) {
  background-color: #fcfcfc;
}

.form-horizontal.form-bordered.form-label-stripped .form-group:nth-child(even) {
  background-color: #fcfcfc;
}

.form-horizontal.form-bordered.form-row-stripped .form-control {
  background: #fff !important;
}

.form-horizontal.form-bordered.form-label-stripped .form-group:nth-child(even)>div {
  background-color: #ffffff;
}

/***
  Bordered form layout
  ***/

.form-bordered .form-control {
  margin: 0;
}


/***
  Disabled Menu Link
  ***/

.disabled-link>a>span.text,
.disabled-link>a>span.title {
  font-style: italic !important;
  color: #888 !important;
}

.disabled-link>a:hover {
  cursor: not-allowed !important;
}


/***
  Responsive & Scrollable Tables
  ***/

.table-scrollable {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  border: 1px solid #dddddd;
  margin: 10px 0 !important;
}

.table-scrollable>.table {
  width: 100% !important;
  margin: 0 !important;
  margin-bottom: 0;
  background-color: #fff;
}

.table-scrollable>.table>thead>tr>th,
.table-scrollable>.table>tbody>tr>th,
.table-scrollable>.table>tfoot>tr>th,
.table-scrollable>.table>thead>tr>td,
.table-scrollable>.table>tbody>tr>td,
.table-scrollable>.table>tfoot>tr>td {
  white-space: nowrap;
}

.table-scrollable>.table-bordered {
  border: 0;
}

.table-scrollable>.table-bordered>thead>tr>th:first-child,
.table-scrollable>.table-bordered>tbody>tr>th:first-child,
.table-scrollable>.table-bordered>tfoot>tr>th:first-child,
.table-scrollable>.table-bordered>thead>tr>td:first-child,
.table-scrollable>.table-bordered>tbody>tr>td:first-child,
.table-scrollable>.table-bordered>tfoot>tr>td:first-child {
  border-left: 0;
}

.table-scrollable>.table-bordered>thead>tr>th:last-child,
.table-scrollable>.table-bordered>tbody>tr>th:last-child,
.table-scrollable>.table-bordered>tfoot>tr>th:last-child,
.table-scrollable>.table-bordered>thead>tr>td:last-child,
.table-scrollable>.table-bordered>tbody>tr>td:last-child,
.table-scrollable>.table-bordered>tfoot>tr>td:last-child {
  border-right: 0;
}

.table-scrollable>.table-bordered>thead>tr:last-child>th,
.table-scrollable>.table-bordered>tbody>tr:last-child>th,
.table-scrollable>.table-bordered>tfoot>tr:last-child>th,
.table-scrollable>.table-bordered>thead>tr:last-child>td,
.table-scrollable>.table-bordered>tbody>tr:last-child>td,
.table-scrollable>.table-bordered>tfoot>tr:last-child>td {
  border-bottom: 0;
}

/***
  Responsive Flip Scroll Tables
  ***/

.flip-scroll table {
  width: 100%;
}

@media only screen and (max-width: 768px) {

  .flip-scroll .flip-content:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }

  .flip-scroll * html .flip-content {
    zoom: 1;
  }

  .flip-scroll *:first-child+html .flip-content {
    zoom: 1;
  }

  .flip-scroll table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  }

  .flip-scroll th,
  .flip-scroll td {
    margin: 0;
    vertical-align: top;
  }

  .flip-scroll th {
    text-align: left;
    border: 0 !important;
    border-bottom: 1px solid #ddd !important;
    border-right: 1px solid #ddd !important;
    font-size: 13px !important;
    padding: 5px;
    width: auto !important;
  }

  .flip-scroll table {
    display: block;
    position: relative;
    width: 100%;
  }

  .flip-scroll thead {
    display: block;
    float: left;
  }

  .flip-scroll tbody {
    display: block;
    width: auto;
    position: relative;
    overflow-x: auto;
    white-space: nowrap;
  }

  .flip-scroll thead tr {
    display: block;
  }

  .flip-scroll th {
    display: block;
    text-align: right;
  }

  .flip-scroll tbody tr {
    display: inline-block;
    vertical-align: top;
    margin-left: -5px;
  }

  .flip-scroll td {
    display: block;
    min-height: 1.25em;
    text-align: left;
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important
  }

  /* sort out borders */

  .flip-scroll th {
    border-bottom: 0;
    border-left: 0;
  }

  .flip-scroll td {
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
  }

  .flip-scroll tbody tr {
    border-left: 1px solid #ddd;
  }

  .flip-scroll th:last-child,
  .flip-scroll td:last-child {
    border-bottom: 1px solid #ddd;
  }

}

/***
  UI Loading
  ***/

.loading-message {
  display: inline-block;
  min-width: 125px;
  padding: 10px;
  margin: 0 auto;
  color: #000 !important;
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
}

.loading-message span {
  line-height: 20px;
  vertical-align: middle;
}

.loading-message.loading-message-boxed {
  border: 1px solid #ddd;
  background-color: #eee;
  -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
}

.page-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 125px;
  margin-left: -50px;
  margin-top: -30px;
  padding: 7px;
  text-align: center;
  color: #333;
  font-size: 13px;
  border: 1px solid #ddd;
  background-color: #eee;
  vertical-align: middle;
  -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
}

.page-loading span {
  line-height: 20px;
  vertical-align: middle;
}

/*volunter availabilty*/
.volunt.blog-article {
  padding: 9px 0 13px 13px;
  width: 98%;
}

.volunt.blog-article .avl span {
  font-size: 15px;
  font-weight: bold;
}

.fr {
  font-weight: normal !important;
  padding: 0 0 0 4px;
}

.avl {
  padding: 0 0 9px;
}

.edit_e {
  float: right;
  background-color: #212121;
  color: #fff;
  border: none;
  outline: none;
  font-size: 15px;
  padding: 2px 7px;
}

.fonta {
  font-size: 20px;
  margin-right: 19px;
  margin-top: 10px;
  color: green
}

.tae {
  padding-top: 20px;
}

.badges_select {
  float: left;
  width: 100%;
}

.badges_select li {
  list-style: none;
  width: 23%;
  margin-left: 1%;
  margin-bottom: 20px;
  display: inline-block
}

.badges_select li input,
.badges_select li label {
  float: left;
}

.badges_select li img {
  width: 100%;
  height: auto;
}

.checker {
  float: left;
}

.detra {
  float: left;
  width: 100%
}

.detail_for_name img {
  width: 10%;
  margin-bottom: 20px;
}

.label_info label {
  margin-top: 0px !important
}

.flare {
  margin-bottom: 10px;
  float: left;
  width: 100%
}

.topac {
  margin-bottom: 20px;
}

.margd {
  margin-left: 20px;
}

.input-group2 {
  position: relative;
  width: 100%;
  display: table;
  border-collapse: separate;
}

.file {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px 0px 0px 20px;
}

.file li {
  float: left;
  list-style: dotted;
  width: 100%;
  font-size: 15px;
  margin-top: 10px;
}

.file li img {
  width: 40px;
  margin-bottom: 10px;
  position: relative;
  top: -7px;
  margin-right: 4px;
}

.file li a {
  text-decoration: none;
  color: #999;
}

.documents {
  margin-bottom: 20px;
}


/***
  Responsive Theme. 
  Based on http://getbootstrap.com/css/#responsive-utilities-classes
  ***/

/***
  Fixed Footer
  ***/

.page-footer-fixed.page-footer-fixed-mobile .footer {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 10000;
  bottom: 0;
}

.page-footer-fixed.page-footer-fixed-mobile .page-container {
  margin-bottom: 20px !important;
}

.page-footer-fixed.page-footer-fixed-mobile.page-sidebar-fixed .footer {
  margin-left: 0 !important;
}

/***
  Form Medium Devices Up To Large Devices
  ***/

@media (min-width: 992px) and (max-width: 1200px) {

  .page-boxed .header.navbar .dropdown .username {
    display: none;
  }

}

@media (max-width: 1024px) {
  .hidden-1024 {
    display: none;
  }
}

/***
  From Medium Devices Up To Larger Devices
  ***/

@media (min-width: 992px) {

  /***
       Page sidebar
       ***/
  .page-sidebar {
    width: 225px;
    float: left;
    position: relative;
    margin-right: -100%;
  }

  .page-sidebar.navbar-collapse {
    max-height: none !important;
  }

  /***
        Page content
        ***/
  .page-content-wrapper {
    float: left;
    width: 100%;
  }

  .page-content {
    margin-left: 225px;
    margin-top: 0px;
    min-height: 600px;
    padding: 25px 20px 20px 20px;
  }

  .page-content.no-min-height {
    min-height: auto;
  }

  /***
      Footer
      ***/
  .footer {
    clear: left;
  }

  /***
        Fixed Sidebar
        ***/
  .page-sidebar-fixed .page-content {
    min-height: 600px;
  }

  .page-sidebar-fixed .page-sidebar {
    position: fixed !important;
    margin-left: 0;
    top: 41px;
  }

  .page-sidebar-fixed ul.page-sidebar-menu>li.last {
    margin-bottom: 15px !important;
  }

  .page-sidebar-fixed.page-sidebar-hover-on .page-sidebar {
    z-index: 10000;
    width: 35px;
  }

  .page-sidebar-fixed.page-sidebar-hover-on .page-sidebar .selected {
    display: none;
  }

  .page-sidebar-fixed.page-sidebar-hover-on .page-content {
    margin-left: 35px;
  }

  .page-sidebar-fixed.page-sidebar-hover-on .footer {
    margin-left: 35px;
  }

  .page-sidebar-fixed .page-sidebar-closed .page-sidebar .sidebar-search .submit,
  .page-sidebar-fixed .page-sidebar .sidebar-toggler {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  .page-sidebar-fixed.page-sidebar-reversed .page-sidebar-closed .page-sidebar .sidebar-search .submit,
  .page-sidebar-fixed.page-sidebar-reversed .page-sidebar .sidebar-toggler {
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none;
  }

  .page-sidebar-hovering {
    overflow: hidden !important;
  }

  .page-sidebar-hovering .sub-menu,
  .page-sidebar-hovering span.badge,
  .page-sidebar-hovering span.title,
  .page-sidebar-hovering span.arrow {
    display: none !important;
  }

  .page-sidebar-hovering .submit {
    opacity: 0;
    width: 0 !important;
    height: 0 !important;
  }

  /***
      Fixed Sidebar
      ***/

  .page-sidebar-fixed .footer {
    margin-left: 225px;
    background-color: #fff;
    padding: 8px 20px 5px 20px;
  }

  .page-sidebar-fixed .footer .footer-inner {
    color: #333;
  }

  .page-sidebar-fixed.page-sidebar-closed .footer {
    margin-left: 35px;
  }

  .page-sidebar-fixed .footer .footer-tools .go-top {
    background-color: #666;
  }

  .page-sidebar-fixed .footer .footer-tools .go-top i {
    color: #ddd;
  }

  /***
      Boxed Layout
      ***/

  .page-boxed .header.navbar .navbar-brand {
    margin-left: 0px !important;
    width: 226px;
  }

  .page-boxed .header.navbar .navbar-brand img {
    margin-left: 10px;
  }

  .page-boxed .header.navbar .navbar-nav {
    margin-right: 0px;
  }

  .page-boxed .footer {
    padding: 8px 0 5px 0;
  }

  .page-boxed.page-sidebar-fixed .footer {
    padding-right: 20px;
    padding-left: 20px;
  }

  /***
      Sidebar Reversed
      ***/

  .page-sidebar-reversed .page-sidebar {
    float: right;
    margin-right: 0;
    margin-left: -100%;
  }

  .page-sidebar-reversed.page-sidebar-fixed .page-sidebar {
    margin-left: -225px;
  }

  .page-sidebar-reversed .page-content {
    margin-left: 0;
    margin-right: 225px;
  }

  .page-sidebar-reversed.page-sidebar-fixed .page-sidebar-wrapper {
    position: relative;
    float: right;
  }

  .page-sidebar-reversed.page-sidebar-fixed .footer {
    margin-left: 0;
    margin-right: 225px;
    padding: 8px 20px 5px 20px;
  }

  .page-sidebar-reversed.page-sidebar-fixed.page-footer-fixed .footer {
    margin-left: 0;
    margin-right: 0;
  }

  .page-sidebar-reversed.page-sidebar-fixed.page-sidebar-hover-on .page-content {
    margin-left: 0;
    margin-right: 35px;
  }

  .page-sidebar-reversed.page-sidebar-fixed.page-sidebar-hover-on .footer {
    margin-right: 35px;
  }

  /***
      Sidebar Closed
      ***/

  .page-sidebar-closed .page-sidebar .sidebar-toggler {
    margin-left: 3px;
  }

  .page-sidebar-closed .page-sidebar .sidebar-search .form-container {
    width: 29px;
    margin-left: 3px;
  }

  .page-sidebar-closed .page-sidebar .sidebar-search .form-container .input-box {
    border-bottom: 0 !important;
  }

  .page-sidebar-closed .page-sidebar .sidebar-search .form-container input[type="text"] {
    display: none;
  }

  .page-sidebar-closed .page-sidebar .sidebar-search .form-container .submit {
    margin-top: 5px !important;
    margin-left: 7px !important;
    margin-right: 7px !important;
    display: block !important;
  }

  .page-sidebar-closed .page-sidebar .sidebar-search.open .form-container {
    width: 255px;
    position: relative;
    z-index: 1;
    padding-top: 0px;
  }

  .page-sidebar-closed .page-sidebar .sidebar-search.open .form-container input[type="text"] {
    margin-top: 7px;
    margin-left: 8px;
    padding-left: 10px;
    padding-bottom: 2px;
    width: 185px;
    display: inline-block !important;
  }

  .page-sidebar-closed .page-sidebar .sidebar-search.open .form-container .submit {
    display: inline-block;
    width: 13px;
    height: 13px;
    margin: 11px 8px 9px 6px !important;
  }

  .page-sidebar-closed .page-sidebar .sidebar-search.open .form-container .remove {
    background-repeat: no-repeat;
    width: 11px;
    height: 11px;
    margin: 12px 8px 9px 8px !important;
    display: inline-block !important;
    float: left !important;
  }

  .page-sidebar-closed .page-sidebar-menu>li>a .selected {
    right: -3px !important;
  }

  .page-sidebar-closed .page-sidebar-menu>li>a>.badge,
  .page-sidebar-closed .page-sidebar-menu>li>a>.title,
  .page-sidebar-closed .page-sidebar-menu>li>a>.arrow {
    display: none !important;
  }

  .page-sidebar-closed .page-sidebar .sidebar-toggler {
    margin-right: 3px;
  }

  .page-sidebar-closed .page-sidebar .sidebar-search {
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .page-sidebar-closed .page-sidebar-menu {
    width: 35px !important;
  }

  .page-sidebar-closed .page-sidebar-menu>li>a {
    padding-left: 7px;
  }

  .page-sidebar-fixed.page-sidebar-closed .page-sidebar-menu>li>a {
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  .page-sidebar-reversed.page-sidebar-fixed.page-sidebar-closed .page-sidebar-menu>li>a {
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none;
  }

  .page-sidebar-closed .page-sidebar-menu>li:hover {
    width: 236px !important;
    position: relative !important;
    z-index: 2000;
    display: block !important;
  }

  .page-sidebar-closed .page-sidebar-menu>li.sidebar-toggler-wrapper:hover,
  .page-sidebar-closed .page-sidebar-menu>li.sidebar-search-wrapper:hover {
    width: 35px !important;
  }

  .page-sidebar-closed .page-sidebar-menu>li:hover .selected {
    display: none;
  }

  .page-sidebar-closed .page-sidebar-menu>li:hover>a>i {
    margin-right: 10px;
  }

  .page-sidebar-closed .page-sidebar-menu>li:hover .title {
    display: inline !important;
  }

  .page-sidebar-closed .page-sidebar-menu>li:hover .badge {
    display: block !important;
  }

  .page-sidebar-closed .page-sidebar-menu>li>.sub-menu {
    display: none !important;
  }

  .page-sidebar-closed .page-sidebar-menu>li:hover>.sub-menu {
    width: 200px;
    position: absolute;
    z-index: 2000;
    left: 36px;
    margin-top: 0;
    top: 100%;
    display: block !important;
  }

  .page-sidebar-closed .page-sidebar-menu>li:hover>.sub-menu>li>.sub-menu,
  .page-sidebar-closed .page-sidebar-menu>li:hover>.sub-menu>li>.sub-menu>li>.sub-menu {
    width: 200px;
  }

  /* 2rd level sub menu*/
  .page-sidebar-closed .page-sidebar-menu>li:hover>.sub-menu>li>a {
    padding-left: 15px !important;
  }

  /* 3rd level sub menu*/
  .page-sidebar-closed .page-sidebar-menu>li>ul.sub-menu>li>.sub-menu>li>a {
    padding-left: 30px !important;
  }

  /* 4rd level sub menu*/
  .page-sidebar-closed .page-sidebar-menu>li>ul.sub-menu>li>.sub-menu>li>.sub-menu>li>a {
    padding-left: 45px !important;
  }

  /* sidebar container */

  .page-sidebar-closed .page-sidebar {
    width: 35px;
  }

  .page-sidebar-closed .page-content {
    margin-left: 35px !important;
  }

  /*** 
      Sidebar Reversed & Sidebar Closed
      ***/

  .page-sidebar-reversed.page-sidebar-closed .page-sidebar {
    margin-left: -35px;
    width: 35px;
  }

  .page-sidebar-reversed.page-sidebar-closed .page-content {
    margin-left: 0 !important;
    margin-right: 35px !important;
  }

  .page-sidebar-reversed.page-sidebar-closed .page-sidebar-menu>li:hover {
    margin-left: -201px;
  }

  .page-sidebar-reversed.page-sidebar-closed .page-sidebar-menu>li.sidebar-toggler-wrapper:hover,
  .page-sidebar-reversed.page-sidebar-closed .page-sidebar-menu>li.sidebar-search-wrapper:hover {
    margin-left: 0;
  }

  .page-sidebar-reversed.page-sidebar-closed .page-sidebar .sidebar-search.open .form-container {
    margin-left: -225px;
  }

  .page-sidebar-reversed.page-sidebar-closed .page-sidebar .sidebar-search.open .form-container .submit {
    margin: 11px 8px 9px 12px !important;
    float: left !important;
  }

  .page-sidebar-reversed.page-sidebar-closed .page-sidebar .sidebar-search.open .form-container .remove {
    margin: 12px 6px 9px 8px !important;
    float: right !important;
  }

  .page-sidebar-reversed.page-sidebar-closed .page-sidebar-menu>li:hover>.sub-menu {
    left: auto;
    right: 36px;
  }

  .page-sidebar-reversed.page-sidebar-fixed.page-sidebar-closed .footer {
    margin-right: 35px;
  }

  /***
      Fixed Footer
      ***/

  .page-footer-fixed .footer {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 10000;
    bottom: 0;
  }

  .page-footer-fixed .page-container {
    margin-bottom: 20px !important;
  }

  .page-footer-fixed.page-sidebar-fixed .footer {
    margin-left: 0 !important;
  }

}

/***
  Up To Medium Devices
  ***/

@media (max-width:991px) {

  /***
       Page header
       ***/
  .header.navbar {
    padding: 0 20px 0 20px;
    position: relative;
    clear: both;
  }

  .page-header-fixed.page-header-fixed-mobile .navbar-fixed-top {
    position: fixed;
  }

  .header.navbar .navbar-toggle {
    display: inline-block;
  }

  .page-sidebar.navbar-collapse {
    max-height: none;
    /* set some max height to have a scrollable menu on mobile devices */
  }

  .page-sidebar.navbar-collapse.collapse {
    display: none !important;
  }

  .page-sidebar.navbar-collapse.in {
    overflow: hidden !important;
    overflow-y: auto !important;
    display: block !important;
  }

  .page-full-width .page-sidebar-menu {
    display: block;
  }

  .page-sidebar.navbar-collapse.navbar-no-scroll {
    max-height: none !important;
  }

  .header.navbar .nav li.dropdown i {
    display: inline-block;
    position: relative;
    top: 1px;
    right: 0px;
  }

  .header.navbar .navbar-nav {
    display: block;
    margin-bottom: 0px !important;
  }

  .header.navbar .navbar-nav .open .dropdown-menu {
    position: absolute;
  }

  .header.navbar .navbar-nav {
    display: inline-block;
    margin: 0 10px 0 0;
  }

  .header.navbar .navbar-nav>li {
    float: left;
  }

  .header.navbar .navbar-brand {
    margin-left: 0px !important;
    padding-left: 0px !important;
  }

  .header.navbar .navbar-brand img {
    margin-left: 4px !important;
  }


  /***
      Header Search Box 
      ***/

  .header.navbar .search-form {
    display: none;
  }

  .page-sidebar .header.navbar-responsive-search {
    display: block;
  }

  /***
      Page container
      ***/
  .page-container {
    margin: 0 !important;
    padding: 0 !important;
  }

  .page-header-fixed.page-header-fixed-mobile .page-container {
    margin-top: 42px !important;
  }

  /***
        Page content
        ***/
  .page-content {
    margin: 0px !important;
    padding: 20px 20px 20px 20px !important;
    min-height: 280px;
  }

  /***
      Page sidebar
      ***/
  .page-sidebar {
    border-top: 0 !important;
    margin: 20px;
  }

  .page-sidebar.in {
    border-top: 0 !important;
    margin: 20px;
    position: relative;
    z-index: 5;
  }

  .header.navbar .sidebar-toggler,
  .page-sidebar .sidebar-toggler {
    display: none;
  }

  .page-sidebar ul {
    margin-top: 0px;
    width: 100%;
  }

  .page-sidebar .selected {
    display: none !important;
  }

  .page-sidebar .sidebar-search .input-box {
    width: 220px;
  }

  /***
      Styler panel
      ***/
  .styler-panel {
    top: 55px;
    right: 20px;
  }

  /***
      Boxed Layout
      ***/
  .page-boxed .header.navbar>.container,
  .page-boxed .footer>.container,
  .page-boxed>.container {
    max-width: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }

}

/***
  From Small Devices Up To Medium Devices
  ***/

@media (min-width: 768px) and (max-width: 991px) {

  /***
      Body
      ***/
  body {
    padding-top: 0px;
  }

  /***
      Page sidebar
      ***/
  .page-sidebar .btn-navbar.collapsed .arrow {
    display: none;
  }

  .page-sidebar .btn-navbar .arrow {
    position: absolute;
    right: 25px;
    width: 0;
    height: 0;
    top: 50px;
    border-bottom: 15px solid #5f646b;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
  }

  /***
      Boxed Layout
      ***/
  .page-boxed .header.navbar>.container,
  .page-boxed>.container {
    margin: auto !important;
  }

  .page-boxed .header.navbar {
    margin: auto !important;
    padding: 0;
  }

  .page-boxed .footer {
    padding-left: 0;
    padding-right: 0;
  }

}

/***
  Extra Small Devices Only
  ***/

@media (max-width: 767px) {

  /***
      Page header
      ***/

  .header.navbar {
    padding: 0 10px 0 10px;
  }

  .header.navbar .top-nav .nav {
    margin-top: 0px;
    margin-right: 5px;
  }

  .header.navbar .nav>li>.dropdown-menu.notification:after,
  .header.navbar .nav>li>.dropdown-menu.notification:before {
    margin-right: 160px;
  }

  .header.navbar .nav>li>.dropdown-menu.notification {
    margin-right: -160px;
  }

  .header.navbar .nav>li>.dropdown-menu.inbox:after,
  .header.navbar .nav>li>.dropdown-menu.inbox:before {
    margin-right: 110px;
  }

  .header.navbar .nav>li>.dropdown-menu.inbox {
    margin-right: -110px;
  }

  .header.navbar .nav>li>.dropdown-menu.tasks:after,
  .header.navbar .nav>li>.dropdown-menu.tasks:before {
    margin-right: 60px;
  }

  .header.navbar .nav>li>.dropdown-menu.tasks {
    margin-right: -60px;
  }

  /* Header logo */
  .header.navbar .navbar-brand {
    margin-left: 0px !important;
    width: 110px;
  }

  /***
      Page content
      ***/
  .page-content {
    padding: 20px 10px 10px 10px !important;
    overflow: hidden;
  }

  /***
      Page title
      ***/
  .page-title {
    margin-bottom: 20px;
    font-size: 18px;
  }

  .page-title small {
    font-size: 13px;
    padding-top: 3px;
  }

  /***
      Styler pagel
      ***/
  .styler-panel {
    top: 58px;
    right: 12px;
  }

  /***
      Page breadcrumb
      ***/
  .breadcrumb {
    padding-left: 10px;
    padding-right: 10px;
  }

  /***
      Portlet form action
      ***/
  .portlet-body.form .form-actions {
    padding-left: 15px;
  }


  /***
      Form input validation states
      ***/
  .input-icon .input-error,
  .input-icon .input-warning,
  .input-icon .input-success {
    top: -27px;
    float: right;
    right: 10px !important;
  }

  /***
      Advance tables
      ***/
  .table-advance tr td.highlight:first-child a {
    margin-left: 8px;
  }

  /***
      Footer	
      ***/
  .footer {
    padding-left: 10px;
    padding-right: 10px;
  }

  .footer .go-top {
    float: right;
    display: block;
    margin-right: 0px;
  }

  /***
      Vertical inline menu
      ***/
  .ver-inline-menu li.active:after {
    display: none;
  }

  /***
      Form controls
      ***/
  .form-horizontal .form-actions {
    padding-left: 180px;
  }

  .portlet .form-horizontal .form-actions {
    padding-left: 190px;
  }
}

/***
  The Most Extra Small Devices Landscape Mode Only
  ***/

@media (max-width: 580px) {

  .header.navbar .username {
    display: none;
  }

}

@media (max-width: 480px) {

  /***
      Header navbar
      ***/
  .page-header-fixed.page-header-fixed-mobile .header.navbar {
    height: 84px;
  }

  .page-header-fixed.page-header-fixed-mobile .page-container {
    margin-top: 84px !important;
  }

  .header.navbar .navbar-nav {
    display: block;
    clear: both;
    margin-top: 2px;
    margin-right: 0;
  }

  .header.navbar .navbar-nav>li.dropdown .dropdown-toggle {
    margin-top: -1px;
    padding-left: 9px;
    padding-right: 9px;
  }

  .header.navbar .navbar-nav>li.dropdown.language .dropdown-toggle,
  .header.navbar .navbar-nav>li.dropdown.user .dropdown-toggle {
    padding-left: 4px;
    padding-right: 0px;
  }

  .header.navbar .navbar-nav li.dropdown .dropdown-toggle .badge {
    top: 8px;
  }

  /***
      Page sidebar
      ***/
  .page-sidebar,
  .page-sidebar.in {
    margin: 0 10px 10px 10px;
  }

  .page-header-fixed.page-header-fixed-mobile .page-sidebar,
  .page-header-fixed.page-header-fixed-mobile .page-sidebar.in {
    margin-top: 10px;
  }

  /***
      Page title
      ***/
  .page-title small {
    display: block;
    clear: both;
  }

  /***
      Forms
      ***/
  .portlet .form-horizontal .form-actions {
    padding-left: 10px;
  }

  /***
      Dashboard date range panel
      ***/
  .page-content .breadcrumb .dashboard-date-range {
    padding-bottom: 8px;
  }

  .page-content .breadcrumb .dashboard-date-range span {
    display: none;
  }

  .page-content .breadcrumb>.btn-group span {
    display: none;
  }

  .page-content .breadcrumb>.btn-group>.btn {
    padding-left: 7px;
    padding-right: 7px;
  }

  /***
      Hidden phone
      ***/
  .hidden-480 {
    display: none !important;
  }
}

/***
  The Most Extra Small Devices Portrait Mode Only
  ***/

@media (max-width: 320px) {

  /***
      Hidden phone
      ***/
  .hidden-320 {
    display: none;
  }

  .header.navbar .navbar-brand {
    width: 100px;
  }
}



/* default color theme  */

/***
  Default theme
  ***/

/***
  Reset and overrides  
  ***/
body {
  background-color: #3d3d3d !important;
}

/***
  Page header
  ***/
.header {
  filter: none !important;
  background-image: none !important;
  background-color: #212121 !important;
}

.header .btn-navbar {
  background-color: #212121 !important;
}

.header .navbar-nav .dropdown-toggle:hover,
.header .navbar-nav .dropdown.open .dropdown-toggle {
  background-color: #383838 !important;
}

.header .navbar-nav li.dropdown .dropdown-toggle i {
  color: #8a8a8a !important;
}

/***
  Header Search
  ***/
.header .search-form {
  background-color: #000;
}

.header .search-form .form-control {
  color: #999;
  border: 0;
  background-color: #000;
}

.header .search-form .form-control::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #777;
}

.header .search-form .form-control:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #777;
}

.header .search-form .form-control::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #777;
}

.header .search-form .form-control:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #777;
}

.header .search-form .submit {
  background: url(assets/img/search-icon-light.png);
}

/***
  Hor menu
  ***/
.header .hor-menu ul.nav li a {
  color: #999;
}

.header .hor-menu ul.nav li.open>a,
.header .hor-menu ul.nav li>a:hover,
.header .hor-menu ul.nav li>a:focus {
  color: #fff;
  background: #383838;
}

.header .hor-menu .dropdown-menu li:hover>a,
.header .hor-menu ul.nav li.active>a,
.header .hor-menu ul.nav li.active>a:hover {
  color: #fff;
  background: #e02222 !important;
}

.header .hor-menu ul.nav li.current>a,
.header .hor-menu ul.nav li.current>a:hover {
  color: #fff;
  background: #e02222 !important;
}

.header .hor-menu .dropdown-menu {
  background: #383838;
}

.header .hor-menu .dropdown-menu li>a {
  color: #999;
}

.header .hor-menu .hor-menu-search-form-toggler.off {
  background: #383838 url(assets/img/hor-menu-search-close.png) no-repeat center;
}

.header .hor-menu .search-form {
  background: #383838;
}

.header .hor-menu .search-form form input {
  color: #999;
}

.header .hor-menu .search-form .btn {
  color: #999;
  background: url(assets/img/search-icon.png) no-repeat center;
}

.header .hor-menu .search-form form input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #999;
}

.header .hor-menu .search-form form input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #999;
}

.header .hor-menu .search-form form input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #999;
}

.header .hor-menu .search-form form input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #999;
}

/***
  Mega Menu
  ***/
.mega-menu .mega-menu-submenu {
  border-right: 1px solid #444;
}

.mega-menu .mega-menu-submenu li h3 {
  color: #fff;
}

/***
  Page sidebar
  ***/
.page-sidebar {
  background-color: #3d3d3d;
}

ul.page-sidebar-menu>li>a {
  border-top: 1px solid #5c5c5c !important;
  color: #ffffff !important;
}

ul.page-sidebar-menu>li:last-child>a {
  border-bottom: 1px solid transparent !important;
}

ul.page-sidebar-menu>li a i {
  color: #969696;
}

ul.page-sidebar-menu>li.open>a,
ul.page-sidebar-menu>li>a:hover,
ul.page-sidebar-menu>li:hover>a {
  background: #303030;
}

ul.page-sidebar-menu>li.active>a {
  background: #1d943b !important;
  border-top-color: transparent !important;
  color: #ffffff;
}

ul.page-sidebar-menu>li.active>a i {
  color: #ffffff;
}

ul.page-sidebar-menu>li>ul.sub-menu>li:first-child>a {
  border-top: 0px !important;
}

ul.page-sidebar-menu>li>ul.sub-menu>li.active>a,
ul.page-sidebar-menu>li>ul.sub-menu>li>a:hover {
  color: #ffffff !important;
  background: #575757 !important;
}

ul.page-sidebar-menu>li>ul.sub-menu>li>a:hover {
  background: #575757 !important;
}

/* 3rd level sub menu */
ul.page-sidebar-menu>li>ul.sub-menu li>ul.sub-menu>li.active>a,
ul.page-sidebar-menu>li>ul.sub-menu li>ul.sub-menu>li>a:hover,
ul.page-sidebar-menu>li>ul.sub-menu li.open>a {
  color: #ffffff !important;
  background: #575757 !important;
}

/* font color for all sub menu links*/
ul.page-sidebar-menu li>ul.sub-menu>li>a {
  color: #bdbdbd;
}

/* menu arrows */
ul.page-sidebar-menu>li>a .arrow:before,
ul.page-sidebar-menu>li>a .arrow.open:before {
  color: #7d7d7d !important;
}

ul.page-sidebar-menu>li>ul.sub-menu a .arrow:before,
ul.page-sidebar-menu>li>ul.sub-menu a .arrow.open:before {
  color: #707070 !important;
}

ul.page-sidebar-menu>li>a>.arrow.open:before {
  color: #8a8a8a !important;
}

ul.page-sidebar-menu>li.active>a .arrow:before,
ul.page-sidebar-menu>li.active>a .arrow.open:before {
  color: #ffffff !important;
}

/* sidebar search */
.page-sidebar .sidebar-search input {
  background-color: #242424 !important;
  color: #7d7d7d;
}

.page-sidebar .sidebar-search input::-webkit-input-placeholder {
  color: #7d7d7d !important;
}

.page-sidebar .sidebar-search input:-moz-placeholder {
  color: #7d7d7d !important;
}

.page-sidebar .sidebar-search input:-ms-input-placeholder {
  color: #7d7d7d !important;
}

.page-sidebar .sidebar-search input {
  background-color: #3d3d3d !important;
  color: #bfbfbf !important;
}

.page-sidebar .sidebar-search .input-box {
  border-bottom: 1px solid #7d7d7d !important;
}

.page-sidebar .sidebar-search .submit {
  background-image: url(assets/img/search-icon.png);
}

/***
  Sidebar toggler
  ***/
.sidebar-toggler {
  background-image: url(assets/img/sidebar-toggler.jpg);
  background-color: #242424;
}

/* search box bg color on expanded */
.page-sidebar-closed .page-sidebar .sidebar-search.open .form-container {
  background-color: #3d3d3d !important;
}

.page-sidebar-closed .page-sidebar .sidebar-search.open .form-container .remove {
  background-image: url("assets/img/sidebar-search-close.png");
}

/* sub menu bg color on hover menu item */
.page-sidebar-closed ul.page-sidebar-menu>li:hover .sub-menu {
  background-color: #3d3d3d;
}

/***
  Footer 
  ***/
.footer .footer-inner {
  color: #999999;
}

.footer .footer-tools .go-top {
  background-color: #4f4f4f;
}

.footer .footer-tools .go-top:hover {
  opacity: 0.7;
  filter: alpha(opacity=70);
}

.footer .footer-tools .go-top i {
  color: #8a8a8a;
}

/***
  Footer Layouts (new in v1.3)
  ***/
/* begin:fixed footer */
.page-footer-fixed .footer {
  background-color: #242424;
}

.page-footer-fixed .footer .footer-inner {
  color: #999999;
}

.page-footer-fixed .footer .footer-tools .go-top {
  background-color: #4f4f4f;
}

.page-footer-fixed .footer .footer-tools .go-top i {
  color: #8a8a8a;
}

/* end:fixed footer */
/***
  Gritter Notifications 
  ***/
/* .gritter-top {
  background: url("assets/plugins/gritter/images/gritter.png") no-repeat left -30px !important;
}

.gritter-bottom {
  background: url("assets/plugins/gritter/images/gritter.png") no-repeat left bottom !important;
}

.gritter-item {
  display: block;
  background: url("assets/plugins/gritter/images/gritter.png") no-repeat left -40px !important;
}

.gritter-close {
  background: url("assets/plugins/gritter/images/gritter.png") no-repeat left top !important;
} */

.gritter-title {
  text-shadow: none !important;
  /* Not supported by IE :( */

}

/* for the light (white) version of the gritter notice */
/* .gritter-light .gritter-item,
.gritter-light .gritter-bottom,
.gritter-light .gritter-top,
.gritter-light .gritter-close {
  background-image: url("assets/plugins/gritter/images/gritter-light.png") !important;
} */

.gritter-item-wrapper a {
  color: #18a5ed;
}

.gritter-item-wrapper a:hover {
  color: #0b6694;
}

/* begin: boxed page */
@media (min-width: 992px) {
  .page-boxed {
    background-color: #2e2e2e !important;
  }

  .page-boxed .page-container {
    background-color: #3d3d3d;
    border-left: 1px solid #5e5e5e;
    border-bottom: 1px solid #5e5e5e;
  }

  .page-boxed.page-sidebar-reversed .page-container {
    border-left: 0;
    border-right: 1px solid #5e5e5e;
  }

  .page-boxed.page-sidebar-fixed .page-container {
    border-left: 0;
    border-bottom: 0;
  }

  .page-boxed.page-sidebar-reversed.page-sidebar-fixed .page-container {
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
  }

  .page-boxed.page-sidebar-fixed .page-sidebar {
    border-left: 1px solid #5e5e5e;
  }

  .page-boxed.page-sidebar-reversed.page-sidebar-fixed .page-sidebar {
    border-right: 1px solid #5e5e5e;
    border-left: 0;
  }

  .page-boxed.page-sidebar-fixed.page-footer-fixed .footer {
    background-color: #2e2e2e !important;
  }
}

/* end: boxed page */
/***
  Landscape phone to portrait tablet
  ***/
@media (max-width: 991px) {

  /***
      page sidebar
      ***/
  .page-sidebar {
    background-color: #292929 !important;
  }

  ul.page-sidebar-menu>li>a {
    border-top: 1px solid #4a4a4a !important;
  }

  ul.page-sidebar-menu>li:last-child>a {
    border-bottom: 0 !important;
  }

  .page-sidebar .sidebar-search input {
    background-color: #292929 !important;
  }

  ul.page-sidebar-menu>li.open>a,
  ul.page-sidebar-menu>li>a:hover,
  ul.page-sidebar-menu>li:hover>a {
    background: #1e1e1e;
  }
}

.btn.green {
  color: white;
  text-shadow: none;
  background-color: #e02222;
  border-radius: 0;
}

label {
  font-weight: normal;
}

.btn.grey {
  background: #595959 !important;
  color: #fff !important;
  margin-left: 10px !important;
  border-radius: 0;
}

/***
  Login page
  ***/

/* logo page */
.login {
  background-color: #444 !important;
}

.login .logo {
  margin: 0 auto;
  margin-top: 60px;
  padding: 15px;
  text-align: center;
}

.login .content {
  background-color: #fff;
  width: 360px;
  margin: 0 auto;
  margin-bottom: 0px;
  padding: 30px;
  padding-top: 20px;
  padding-bottom: 15px;
}

.login .content h3 {
  color: #000;
}

.login .content h4 {
  color: #555;
}

.login .content p {
  color: #222;
}

.login .content .login-form,
.login .content .forget-form {
  padding: 0px;
  margin: 0px;
}

.login .content .input-icon {
  border-left: 2px solid #35aa47 !important;
}

.login .content .input-icon .form-control {
  border-left: 0;
}

.login .content .forget-form {
  display: none;
}

.login .content .register-form {
  display: none;
}

.login .content .form-title {
  font-weight: 300;
  margin-bottom: 25px;
}

.login .content .form-actions {
  background-color: #fff;
  clear: both;
  border: 0px;
  border-bottom: 1px solid #eee;
  padding: 0px 30px 25px 30px;
  margin-left: -12px;
  margin-right: -30px;
}

.login .content .form-actions .checkbox {
  margin-left: 0;
  padding-left: 0;
}

.login .content .forget-form .form-actions {
  border: 0;
  margin-bottom: 0;
  padding-bottom: 20px;
}

.login .content .register-form .form-actions {
  border: 0;
  margin-bottom: 0;
  padding-bottom: 0px;
}

.login .content .form-actions .checkbox {
  margin-top: 8px;
  display: inline-block;
}

.login .content .form-actions .btn {
  margin-top: 1px;
}

.login .content .forget-password {
  margin-top: 25px;
}

.login .content .create-account {
  border-top: 1px dotted #eee;
  padding-top: 10px;
  margin-top: 15px;
}

.login .content .create-account a {
  display: inline-block;
  margin-top: 5px;
}

/* select2 dropdowns */
.login .content .select2-container {
  border-left: 2px solid #35aa47 !important;
}

.login .content .select2-container .select2-choice {
  border-left: none !important;
}

.login .content .select2-container i {
  display: inline-block;
  position: relative;
  color: #ccc;
  z-index: 1;
  top: 1px;
  margin: 4px 4px 0px 3px;
  width: 16px;
  height: 16px;
  font-size: 16px;
  text-align: center;
}

.login .content .has-error .select2-container i {
  color: #b94a48;
}

.login .content .select2-container a span {
  font-size: 13px;
}

.login .content .select2-container a span img {
  margin-left: 4px;
}

/* footer copyright */
.login .copyright {
  text-align: center;
  margin: 0 auto;
  padding: 10px;
  color: #999;
  font-size: 13px;
}

@media (max-width: 480px) {

  /***
    Login page
    ***/
  .login .logo {
    margin-top: 10px;
  }

  .login .content {
    width: 280px;
  }

  .login .content h3 {
    font-size: 22px;
  }


  .login .checkbox {
    font-size: 13px;
  }
}

/* remove rounds from all elements */

div,
input,
select,
textarea,
span,
img,
table,
td,
th,
p,
a,
button,
ul,
code,
pre,
li {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}

/***
  Buttons & Dropdown Buttons
  ***/

.btn {
  border-width: 0;
  padding: 7px 14px;
  font-size: 14px;
  outline: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
  text-shadow: none;
}

/* fix jumping group buttons */
.btn-group.btn-group-solid .btn+.btn,
.btn-group.btn-group-solid .btn+.btn-group.btn-group-solid,
.btn-group.btn-group-solid .btn-group.btn-group-solid+.btn,
.btn-group.btn-group-solid .btn-group.btn-group-solid+.btn-group.btn-group-solid {
  margin-left: 0px;
}

.btn-group-vertical.btn-group-solid>.btn+.btn,
.btn-group-vertical.btn-group-solid>.btn+.btn-group,
.btn-group-vertical.btn-group-solid>.btn-group+.btn,
.btn-group-vertical.btn-group-solid>.btn-group+.btn-group {
  margin-top: 0px;
  margin-left: 0;
}

.btn-default {
  border-width: 1px;
  padding: 6px 13px;
}

.btn.red-stripe {
  border-left: 3px solid #d84a38;
}

.btn.blue-stripe {
  border-left: 3px solid #4d90fe;
}

.btn.purple-stripe {
  border-left: 3px solid #852b99;
}

.btn.green-stripe {
  border-left: 3px solid #35aa47;
}

.btn.yellow-stripe {
  border-left: 3px solid #ffb848;
}

.btn.dark-stripe {
  border-left: 3px solid #555555;
}

.btn.default {
  color: #333333;
  text-shadow: none;
  background-color: #e5e5e5;
}

.btn.default:hover,
.btn.default:focus,
.btn.default:active,
.btn.default.active,
.btn.default[disabled],
.btn.default.disabled {
  color: #333333;
  background-color: #d8d8d8 !important;
  outline: none !important;
}

/*  Red */
.btn.red {
  color: white;
  text-shadow: none;
  background-color: #d84a38;
}

.btn.red:hover,
.btn.red:focus,
.btn.red:active,
.btn.red.active,
.btn.red[disabled],
.btn.red.disabled {
  background-color: #bb2413 !important;
  color: #fff !important;
  outline: none !important;
}

/*  Blue */

.btn.blue {
  color: white;
  text-shadow: none;
  background-color: #4d90fe;
}

.btn.blue:hover,
.btn.blue:focus,
.btn.blue:active,
.btn.blue.active,
.btn.blue[disabled],
.btn.blue.disabled {
  background-color: #0362fd !important;
  color: #fff !important;
  outline: none !important;
}

.btn-group .btn.blue.dropdown-toggle {
  background-color: #4d90fe !important;
}

.btn-group .btn.blue:hover,
.btn-group .btn.blue:focus,
.btn-group .btn.blue:active,
.btn-group .btn.blue.active,
.btn-group .btn.blue.disabled,
.btn-group .btn.blue[disabled] {
  background-color: #0362fd !important;
  color: #fff !important;
  outline: none !important;
}

/*  Green */
.btn.green {
  color: white;
  text-shadow: none;
  background-color: #35aa47;
}

.btn.green:hover,
.btn.green:focus,
.btn.green:active,
.btn.green.active,
.btn.green.disabled,
.btn.green[disabled] {
  background-color: #1d943b !important;
  color: #fff !important;
  outline: none !important;
}

/*  Purple */
.btn.purple {
  color: white;
  text-shadow: none;
  background-color: #852b99;
}

.btn.purple:hover,
.btn.purple:focus,
.btn.purple:active,
.btn.purple.active,
.btn.purple.disabled,
.btn.purple[disabled] {
  background-color: #6d1b81 !important;
  color: #fff !important;
  outline: none !important;
}

.btn-group .btn.purple.dropdown-toggle {
  background-color: #852b99 !important;
}

.btn-group .btn.purple:hover,
.btn-group .btn.purple:focus,
.btn-group .btn.purple:active,
.btn-group .btn.purple.active,
.btn-group .btn.purple.disabled,
.btn-group .btn.purple[disabled] {
  background-color: #6d1b81 !important;
  color: #fff !important;
  outline: none !important;
}

/*  Yellow */
.btn.yellow {
  color: white;
  text-shadow: none;
  background-color: #ffb848;
}

.btn.yellow:hover,
.btn.yellow:focus,
.btn.yellow:active,
.btn.yellow.active,
.btn.yellow.disabled,
.btn.yellow[disabled] {
  background-color: #eca22e !important;
  color: #fff !important;
  outline: none !important;
}

.btn-group .btn.yellow.dropdown-toggle {
  background-color: #ffb848 !important;
}

.btn-group .btn.yellow:hover,
.btn-group .btn.yellow:focus,
.btn-group .btn.yellow:active,
.btn-group .btn.yellow.active,
.btn-group .btn.yellow.disabled,
.btn-group .btn.yellow[disabled] {
  background-color: #eca22e !important;
  color: #fff !important;
  outline: none !important;
}

/*  Black */
.btn.dark {
  color: white;
  text-shadow: none;
  background-color: #555555;
}

.btn.dark:hover,
.btn.dark:focus,
.btn.dark:active,
.btn.dark.active,
.btn.dark.disabled,
.btn.dark[disabled] {
  background-color: #222222 !important;
  color: #fff !important;
  outline: none !important;
}

.btn-group .btn.dark.dropdown-toggle {
  background-color: #555555 !important;
}

.btn-group .btn.dark:hover,
.btn-group .btn.dark:focus,
.btn-group .btn.dark:active,
.btn-group .btn.dark.active,
.btn-group .btn.dark.disabled,
.btn-group .btn.dark[disabled] {
  background-color: #222222 !important;
  color: #fff !important;
  outline: none !important;
}

.btn-lg {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  vertical-align: middle;
}

.btn-lg>i {
  font-size: 18px;
}

.btn>i {
  font-size: 14px;
}

.btn-sm,
.btn-xs {
  padding: 4px 10px 5px 10px;
  font-size: 13px;
  line-height: 1.5;
}

.btn-sm>i,
.btn-xs>i {
  font-size: 13px;
}

.btn-xs {
  padding: 1px 5px;
}

.btn-block {
  display: block;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}

/***
  Metro icons
  ***/

[class^="m-icon-"] {
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-top: 3px;
  line-height: 14px;
  vertical-align: top;
  background-image: url(assets/img/syncfusion-icons.png);
  background-position: 0 0;
  background-repeat: no-repeat;
}

[class^="m-icon-big-"] {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin: 6px;
  vertical-align: middle;
  background-image: url(assets/img/syncfusion-icons.png);
  background-position: 0 0px;
  background-repeat: no-repeat;
}

/* large icons */
.btn.m-icon-big {
  padding: 9px 16px 8px 16px;
}

.btn.m-icon-big.m-icon-only {
  padding: 9px 8px 8px 0px;
}

.btn.m-icon-big [class^="m-icon-big-"] {
  margin: 0 0 0 10px;
}

.btn.m-icon-ony>i {
  margin-left: 0px;
}

/* default icons */
.btn.m-icon {
  padding: 7px 14px 7px 14px;
}

.btn.m-icon [class^="m-icon-"] {
  margin: 4px 0 0 5px;
}

.btn.m-icon.m-icon-only {
  padding: 7px 10px 7px 6px;
}

/* white icon */
.m-icon-white {
  background-image: url(assets/img/syncfusion-icons-white.png);
}

/*  Misc */
.m-icon-swapright {
  background-position: -27px -10px;
}

.m-icon-swapdown {
  background-position: -68px -10px;
}

.m-icon-swapleft {
  background-position: -8px -10px;
}

.m-icon-swapup {
  background-position: -46px -10px;
}

.m-icon-big-swapright {
  background-position: -42px -28px;
}

.m-icon-big-swapdown {
  background-position: -115px -28px;
}

.m-icon-big-swapleft {
  background-position: -6px -28px;
}

.m-icon-big-swapup {
  background-position: -78px -28px;
}


/***
  Popover
   ***/
.popover {
  -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
  padding: 0 !important;
}

.popover .popover-title {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
  margin: 0 !important;
}

.info .popover .popover-title,
.popover.info .popover-title,
.info .popover .popover-content,
.popover.info .popover-content {
  color: #27a9e3;
}

.success .popover .popover-title,
.popover.success .popover-title,
.success .popover .popover-content,
.popover.success .popover-content {
  color: #468847;
}

.error .popover .popover-title,
.popover.error .popover-title,
.error .popover .popover-content,
.popover.error .popover-content {
  color: #B94A48;
}

.warning .popover .popover-title,
.popover.warning .popover-title,
.warning .popover .popover-content,
.popover.warning .popover-content {
  color: #C09853;
}

.popovers.yellow+.popover {
  background: yellow;
}

.popovers.yellow+.popover .popover-title {
  background: yellow;
}

.popovers.yellow+.popover .popover-content {
  background: yellow;
}

/***
  Dropdown
  ***/

/*Fixing dropdown issue on mobile devices in Bootstrap 3.2.2*/
.dropdown-backdrop {
  position: static;
}

.dropdown:hover .caret,
.open.dropdown .caret {
  opacity: 1;
  filter: alpha(opacity=100);
}


.dropdown.open .dropdown-toggle {
  color: #08c;
  background: #ccc;
  background: rgba(0, 0, 0, 0.3);
}

/***
  Dropdown Menu
  ***/

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  list-style: none;
  text-shadow: none;
  padding: 0px;
  margin: 0px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-family: "Segoe UI", Helvetica, Arial, sans-serif;
  border: 1px solid #ddd;
}

/* custom dropdown conetnt */
.dropdown-content {
  padding: 5px;
}

.dropdown-content form {
  margin: 0;
}

.dropdown.inline .dropdown-menu {
  display: inline-block;
  position: relative;
}

.dropdown-menu.bottom-up {
  top: auto;
  bottom: 100%;
  margin-bottom: 2px;
}

.dropdown-menu li>a {
  padding: 6px 0 6px 13px;
  color: #333;
  text-decoration: none;
  display: block;
  clear: both;
  font-weight: normal;
  line-height: 18px;
  white-space: nowrap;
}

.dropdown-menu li>a:hover,
.dropdown-menu .active>a,
.dropdown-menu .active>a:hover {
  text-decoration: none;
  background-image: none;
  background-color: #eee;
  color: #333;
  filter: none;
}

/* dropdown sub menu support for Bootsrap 3 */
.dropdown-submenu {
  position: relative;
}

.dropdown-submenu>.dropdown-menu {
  top: 5px;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
}

.dropdown-submenu:hover>.dropdown-menu {
  display: block;
}

.dropup .dropdown-submenu>.dropdown-menu {
  top: auto;
  bottom: 0;
  margin-top: 0;
  margin-bottom: -2px;
}

.dropdown-submenu>a:after {
  position: absolute;
  display: inline-block;
  font-size: 14px;
  right: 7px;
  top: 7px;
  font-family: FontAwesome;
  height: auto;
  content: "\f105";
  font-weight: 300;
}

.dropdown-submenu:hover>a:after {
  border-left-color: #ffffff;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left>.dropdown-menu {
  left: -100%;
  margin-left: 10px;
}

.nav.pull-right>li>.dropdown-menu,
.nav>li>.dropdown-menu.pull-right {
  right: 0;
  left: auto;
}

.nav.pull-right>li>.dropdown-menu:before,
.nav>li>.dropdown-menu.pull-right:before {
  right: 12px;
  left: auto;
}

.nav.pull-right>li>.dropdown-menu:after,
.nav>li>.dropdown-menu.pull-right:after {
  right: 13px;
  left: auto;
}

.nav.pull-right>li>.dropdown-menu .dropdown-menu,
.nav>li>.dropdown-menu.pull-right .dropdown-menu {
  right: 100%;
  left: auto;
  margin-right: -1px;
  margin-left: 0;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}

@media (max-width: 767px) {

  .navbar-nav .open .dropdown-menu {
    position: absolute;
    float: left;
    width: auto;
    margin-top: 0;
    background-color: #ffffff;
    border: 1px solid #ddd;
    -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  }

  .navbar-nav .open .dropdown-menu>li>a {
    padding: 6px 0 6px 13px;
    color: #333 !important;
  }

  .navbar-nav .open .dropdown-menu>li>a:hover,
  .navbar-nav .open .dropdown-menu>li>a:focus {
    background-color: #eee !important;
  }

}

/***
  Dropdown Checkboxes (in v1.3)
  ***/
.dropdown-checkboxes {
  padding: 5px;
}

.dropdown-checkboxes label {
  display: block;
  font-weight: 300;
  color: #333;
  margin-bottom: 4px;
  margin-top: 4px;
}


/***
  Dropdown Menu Badges
  ***/

.dropdown-menu>li>a>.badge {
  position: absolute;
  margin-top: 1px;
  right: 3px;
  display: inline;
  font-size: 11px;
  font-weight: 300;
  text-shadow: none;
  height: 18px;
  padding: 3px 6px 3px 6px;
  text-align: center;
  vertical-align: middle;
  -webkit-border-radius: 12px !important;
  -moz-border-radius: 12px !important;
  border-radius: 12px !important;
}

.dropdown-menu>li>a>.badge.badge-roundless {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}

/* end: sidebar menu badges */

/***
  Forms
  ***/
code {
  border: 1px solid #e1e1e1;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

label {
  font-weight: 400;
  font-size: 14px;
}

.form-control:-moz-placeholder {
  color: #999999;
}

.form-control::-moz-placeholder {
  color: #999999;
}

.form-control:-ms-input-placeholder {
  color: #999999;
}

.form-control::-webkit-input-placeholder {
  color: #999999;
}

.form-control {
  font-size: 14px;
  font-weight: normal;
  color: #333333;
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.form-control:focus {
  border-color: #999999;
  outline: 0;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  cursor: not-allowed;
  background-color: #eeeeee;
}

.form-control.height-auto {
  height: auto;
}

.uneditable-input {
  padding: 6px 12px;
  min-width: 206px;
  font-size: 14px;
  font-weight: normal;
  height: 34px;
  color: #333333;
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

label.form-control {
  display: block;
  margin-bottom: 5px;
}

input[disabled],
select[disabled],
textarea[disabled] {
  cursor: not-allowed;
  background-color: #F4F4F4 !important;
}

input[readonly],
select[readonly],
textarea[readonly] {
  cursor: not-allowed;
  background-color: #F9F9F9 !important;
}

/* input  groups */
.input-group.input-group-fixed {
  width: auto !important;
}

.input-group-addon {
  border-color: #e5e5e5;
  background: #e5e5e5;
  min-width: 39px;
}

.input-group-addon>i {
  color: #999;
}

/* form control sizing */
.form-control-inline {
  display: inline-block !important;
}

.input-mini {
  width: 45px !important;
}

.input-xsmall {
  width: 80px !important;
}

.input-small {
  width: 120px !important;
}

.input-medium {
  width: 240px !important;
}

.input-large {
  width: 320px !important;
}

.input-xlarge {
  width: 480px !important;
}

.input-inline {
  display: inline-block;
  width: auto;
  vertical-align: middle;
}

.form-group .input-inline {
  margin-right: 5px;
}

.input-sm {
  height: 28px;
  padding: 5px 10px;
  font-size: 13px;
}

select.input-sm {
  height: 28px;
  line-height: 28px;
  padding: 2px 10px;
}

/***
  Input spinner(in v1.4)
  ***/

input[type="text"].spinner,
input[type="password"].spinner,
input[type="datetime"].spinner,
input[type="datetime-local"].spinner,
input[type="date"].spinner,
input[type="month"].spinner,
input[type="time"].spinner,
input[type="week"].spinner,
input[type="number"].spinner,
input[type="email"].spinner,
input[type="url"].spinner,
input[type="search"].spinner,
input[type="tel"].spinner,
input[type="color"].spinner {
  background-image: url("assets/img/input-spinner.gif") !important;
  background-repeat: no-repeat;
  background-position: right 8px;
}

@media (max-width: 768px) {

  .input-large {
    width: 250px !important;
  }

  .input-xlarge {
    width: 300px !important;
  }

}

/***
  Error States
  ***/

.has-warning .help-inline,
.has-warning .help-block,
.has-warning .control-label {
  color: #c09853;
}

.has-warning .form-control {
  border-color: #c09853;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.has-warning .form-control:focus {
  border-color: #a47e3c;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.has-warning .input-group-addon {
  color: #c09853;
  background-color: #fcf8e3;
  border-color: #c09853;
}

.has-error .help-inline,
.has-error .help-block,
.has-error .control-label {
  color: #b94a48;
}

.has-error .form-control {
  border-color: #b94a48;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.has-error .form-control:focus {
  border-color: #953b39;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.has-error .input-group-addon {
  color: #b94a48;
  background-color: #f2dede;
  border-color: #b94a48;
}

.has-success .help-inline,
.has-success .help-block,
.has-success .control-label {
  color: #468847;
}

.has-success .form-control {
  border-color: #468847;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.has-success .form-control:focus {
  border-color: #356635;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.has-success .input-group-addon {
  color: #468847;
  background-color: #dff0d8;
  border-color: #468847;
}

/***
  Custom label and badges
  ***/

.label,
.badge {
  font-weight: 300;
  text-shadow: none !important;
}

.label {
  font-size: 12px;
  padding: 3px 6px 3px 6px;
}

.label.label-sm {
  font-size: 12px;
  padding: 1px 4px 1px 4px;
}

h1 .label,
h2 .label,
h3 .label,
h4 .label,
h5 .label,
h6 .label,
h7 .label {
  font-size: 75%;
}

.badge {
  font-size: 14px !important;
  font-weight: 300;
  text-align: center;
  background-color: #e02222;
  height: 18px;
  padding: 3px 6px 3px 6px;
  -webkit-border-radius: 12px !important;
  -moz-border-radius: 12px !important;
  border-radius: 12px !important;
  text-shadow: none !important;
  text-align: center;
  vertical-align: middle;
}

.badge.badge-roundless {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}

.badge-default,
.label-default {
  background-color: #999 !important;
}

ul li {
  cursor: pointer
}

.badge-primary,
.label-primary {
  background-color: #428bca !important;
}

.center {
  margin: auto;
  width: 60%;
  margin-left: 17%;
  /* border: 3px solid green; */
  padding: 2px;
}

.label-success,
.badge-success {
  background-color: #3cc051;
  background-image: none !important;
}

.label-warning,
.badge-warning {
  background-color: #fcb322;
  background-image: none !important;
}

.label-danger,
.badge-danger {
  background-color: #ed4e2a;
  background-image: none !important;
}

.label-info,
.badge-info {
  background-color: #57b5e3;
  background-image: none !important;
}

/* fix badge position for navs */
.nav.nav-pills>li>a>.badge {
  margin-top: -2px;
}

.nav.nav-stacked>li>a>.badge {
  margin-top: 1px;
  margin-bottom: 0px;
}

/***
  Iconic Labels
  ***/

.label.label-icon {
  padding: 4px 1px 4px 5px;
  margin-right: 2px;
  text-align: center !important;
}

.ie9 .label.label-icon,
.ie10 .label.label-icon {
  padding: 3px 0px 3px 3px;
}

.label.label-icon>i {
  font-size: 12px;
  text-align: center !important;
}


/***
  Progress Bars
  ***/

.progress {
  border: 0;
  background-image: none !important;
  filter: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;

}

.progress>.progress-bar-success {
  background-color: #3cc051;
}

.progress>.progress-bar-danger {
  background-color: #ed4e2a;
}

.progress>.progress-bar-info {
  background-color: #57b5e3;
}

.progress>.progress-bar-warning {
  background-color: #fcb322;
}


/***
  Pagination
  ***/
.pagination {
  margin: 10px 0;
}

.pagination .active>a,
.pagination .active>a:hover {
  background: #eee;
  border-color: #dddddd;
  color: #333;
}



/***
  wells
  ***/
.well {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

/* Bootstrap Tabs */

.dropup.open>.dropdown-toggle,
.dropdown.open>.dropdown-toggle {
  border-color: #ddd !important;
}

.nav-tabs>li>.dropdown-menu:after,
.nav-pills>li>.dropdown-menu:after,
.navbar-nav>li>.dropdown-menu:after,

.nav-tabs>li>.dropdown-menu:before,
.nav-pills>li>.dropdown-menu:before,
.navbar-nav>li>.dropdown-menu:before {
  display: none !important;
}

.nav-tabs>.dropdown.open>.dropdown-toggle,
.nav-pills>.dropdown.open>.dropdown-toggle {
  background: #eee !important;
  color: #0d638f !important;
}


.nav-tabs,
.nav-pills {
  margin-bottom: 10px;
}

.tabs-right>.nav-tabs,
.tabs-left>.nav-tabs {
  border-bottom: 0;
}

.tabs-left>.nav-tabs>li,
.tabs-right>.nav-tabs>li {
  float: none;
}

.tabs-left>.nav-tabs>li>a,
.tabs-right>.nav-tabs>li>a {
  min-width: 74px;
  margin-right: 0;
  margin-bottom: 3px;
}

.tabs-left>.nav-tabs {
  float: left;
  margin-right: 19px;
  border-right: 1px solid #ddd;
}

.tabs-left>.nav-tabs>li>a {
  margin-right: -1px;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}

.tabs-left>.nav-tabs>li>a:hover,
.tabs-left>.nav-tabs>li>a:focus {
  border-color: #eeeeee #dddddd #eeeeee #eeeeee;
}

.tabs-left>.nav-tabs .active>a,
.tabs-left>.nav-tabs .active>a:hover,
.tabs-left>.nav-tabs .active>a:focus {
  border-color: #ddd transparent #ddd #ddd;
  *border-right-color: #ffffff;
}

.tabs-right>.nav-tabs {
  float: right;
  margin-left: 19px;
  border-left: 1px solid #ddd;
}

.tabs-right>.nav-tabs>li>a {
  margin-left: -1px;
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}

.tabs-right>.nav-tabs>li>a:hover,
.tabs-right>.nav-tabs>li>a:focus {
  border-color: #eeeeee #eeeeee #eeeeee #dddddd;
}

.tabs-right>.nav-tabs .active>a,
.tabs-right>.nav-tabs .active>a:hover,
.tabs-right>.nav-tabs .active>a:focus {
  border-color: #ddd #ddd #ddd transparent;
  *border-left-color: #ffffff;
}

.tabs-below>.nav-tabs,
.tabs-below>.nav-pills {
  border-bottom: 0;
  margin-bottom: 0px;
  margin-top: 10px;
}

.tabs-below>.nav-tabs {
  border-top: 1px solid #ddd;
  margin-bottom: 0;
  margin-top: 10px;
}

.tabs-below>.nav-tabs>li {
  margin-top: -1px;
  margin-bottom: 0;
}

.tabs-below>.nav-tabs>li>a {
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}

.tabs-below>.nav-tabs>li>a:hover,
.tabs-below>.nav-tabs>li>a:focus {
  border-top-color: #ddd;
  border-bottom-color: transparent;
}

.tabs-below>.nav-tabs>.active>a,
.tabs-below>.nav-tabs>.active>a:hover,
.tabs-below>.nav-tabs>.active>a:focus {
  border-color: transparent #ddd #ddd #ddd;
}

/* BS3.0.3 removed tabbable class so its added back */
.tabbable:before,
.tabbable:after {
  display: table;
  content: " ";
}

.tabbable:after {
  clear: both;
}

.tabbable:before,
.tabbable:after {
  display: table;
  content: " ";
}

.tabbable:after {
  clear: both;
}

/***
  Bootstrap modal 
  ***/

.modal {
  z-index: 10050 !important;
  outline: none !important;
}

.modal-header {
  border-bottom: 1px solid #EFEFEF;
}

.modal-header h3 {
  font-weight: 300;
}

.modal-small.modal-dialog {
  width: 400px;
}

.modal-wide.modal-dialog {
  width: 60%;
}

.modal-full.modal-dialog {
  width: 100%;
}

@media (max-width: 768px) {

  .modal-small.modal-dialog,
  .modal-wide.modal-dialog,
  .modal-full.modal-dialog {
    width: auto;
  }

}

/***
  Modal header close button fix
  ***/
.modal-header .close {
  margin-top: 0px !important;
}

.modal>.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
}

.modal-backdrop {
  border: 0 !important;
  outline: none !important;
  z-index: 10049 !important;
}

.modal-backdrop,
.modal-backdrop.fade.in {
  background-color: #333 !important;
}

/* fix: content shifting to the right on modal open due to scrollbar closed */
.modal {
  overflow-y: auto !important;
}

.modal-open {
  overflow-y: auto !important;
}

.modal-open-noscroll {
  overflow-y: hidden !important;
}


/***
  Image Carousel
  ***/
.carousel.image-carousel .carousel-inner {
  padding-top: 0;
  padding-bottom: 0;
}

.carousel.image-carousel .carousel-control i {
  position: absolute;
  top: 40%;
}

.carousel.image-carousel.image-carousel-hoverable .carousel-control i {
  display: none;
}

.carousel.image-carousel.image-carousel-hoverable:hover .carousel-control i {
  display: inline-block;
}

.carousel.image-carousel .carousel-control.left i {
  left: 10px;
}

.carousel.image-carousel .carousel-control.right i {
  right: 10px;
}

.carousel.image-carousel .carousel-indicators {
  margin-top: 10px;
  bottom: -7px;
}

.carousel.image-carousel .carousel-indicators li {
  background-color: #666;
}

.carousel.image-carousel .carousel-indicators li.active {
  background-color: #333;
}

.carousel.image-carousel .carousel-caption {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 15px 15px 25px 15px;
  background: #333333;
  background: rgba(0, 0, 0, 0.75);
}

.carousel.image-carousel .carousel-caption h4,
.carousel.image-carousel .carousel-caption h3,
.carousel.image-carousel .carousel-caption h2,
.carousel.image-carousel .carousel-caption p {
  text-align: left;
  line-height: 20px;
  color: #ffffff;
}

.carousel.image-carousel .carousel-caption h2,
.carousel.image-carousel .carousel-caption h3,
.carousel.image-carousel .carousel-caption h4 {
  margin: 0 0 5px;
}

.carousel.image-carousel .carousel-caption h2 a,
.carousel.image-carousel .carousel-caption h3 a,
.carousel.image-carousel .carousel-caption h4 a {
  color: #aaa;
}

.carousel.image-carousel .carousel-caption p {
  margin-bottom: 0;
}

.carousel.image-carousel .item {
  margin: 0;
}

/***
  Bootstrap Tables
  ***/

.table thead>tr>th {
  border-bottom: 0;
}

.table tbody tr.active td,
.table tbody tr.active th {
  background-color: #e9e9e9 !important;
}

.table tbody tr.active:hover td,
.table tbody tr.active:hover th {
  background-color: #e1e1e1 !important;
}

.table-striped tbody tr.active:nth-child(odd) td,
.table-striped tbody tr.active:nth-child(odd) th {
  background-color: #017ebc;
}

.table .heading>th {
  background-color: #eee !important;
}

/***
  Bootstrap Panel
  ***/

.panel {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.panel .panel-title>a:hover {
  text-decoration: none;
}

.accordion .panel-heading {
  padding: 0;
}

.accordion .panel-title {
  padding: 0;
}

.accordion .panel-title .accordion-toggle {
  display: block;
  padding: 10px 15px;
}

.accordion .accordion-toggle.accordion-toggle-styled {
  background: url("assets/img/accordion-plusminus.png") no-repeat;
  background-position: right -19px;
  margin-right: 15px;
}

.accordion .accordion-toggle.accordion-toggle-styled.collapsed {
  background-position: right 12px;
}

/***
  Responsive Image
  ***/
.table td .img-responsive {
  width: 100%;
}

/***
  Unstyled List
  ***/

.list-unstyled li>.list-unstyled {
  margin-left: 25px;
}

/***
Page sidebar
***/

.page-sidebar.navbar-collapse {
  padding: 0;
}

.page-sidebar-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  margin: 0;
  padding: 0;
}

.page-sidebar-menu>li {
  display: block;
  margin: 0;
  padding: 0;
  border: 0px;
}

.page-sidebar-menu>li.start>a {
  border-top-color: transparent !important;
}

.page-sidebar-menu>li:last-child>a,
.page-sidebar-menu>li.last>a {
  border-bottom-color: transparent !important;
}

.page-sidebar-menu>li>a {
  display: block;
  position: relative;
  margin: 0;
  border: 0px;
  padding: 10px 15px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 300;
}

.page-sidebar-fixed .page-sidebar-menu>li>a {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.page-sidebar-reversed.page-sidebar-fixed .page-sidebar-menu>li>a {
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
}


/* himanshi changes */
a {
  cursor: pointer;
}
.uploadImage {
  max-width: 200px;
  min-height: 250px;
  position: relative;
  overflow: hidden;
}
.uploadImage img {
  bottom: 40px;
  left: 0;
  position: absolute;
  max-width: 100%;
  width: 100%;
}
.uploadImage input {
  text-indent: -9999px;
  display: none;
}
.uploadImage label {
  background: orange !important;
  bottom: 0;
  color: #ffffff;
  display: block;
  left: 0;
  margin: 0;
  cursor: pointer;
  padding: 10px;
  position: absolute;
  text-align: center;
  width: 100%;
}
button.btn.default {
  margin: 0 10px 0 0;
}
.footer .footer-inner {
  float: none;
  display: inline-block;
  text-align: center;
  width: 100%;
  padding-top: 18px;
}
form#search {
  margin-bottom: 15px;
}
.ck-editor__editable {
  min-height: 250px !important;
}

.header.navbar .navbar-brand {
    margin-left: 0!important;
    width: 110px;
}
a.navbar-brand {
  padding: 11px 10px 15px 15px!important;
  color: #fff;
  font-weight: 700;
}

bs-datepicker-container, bs-daterangepicker-container {
  z-index: 999999 !important;
}
.header-inner {
  width: 100%;
}
